<template>
    <div>
        <!--
                <v-img
                        width="100%"
                        :src="require('@/assets/children-over-london-5.jpg')"
                >
                    <v-container class="mt-10">
                        <v-row
                                align="start"
                                justify="center"
                        >
                            <v-col
                                    cols="12" md="4" offset-md="8"
                            >
                                <v-card
                                        outlined
                                        raised
                                        color="rgba(255, 255, 255, 0.92)"
                                        class="gray-border"
                                        min-width="330px"
                                >
                                    <v-card-title class="h1-text pb-3">
                                        Подарите ребенку новые возможности!
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="h2-text">
                                        Уверенно говорить, читать, смотреть кинофильмы и телепередачи на английском
                                    </v-card-text>
                                    <v-card-text class="px-3 fill-height">
                                        <div class="text-center">
                                            <v-btn class="mt-2 mb-2" color="deep-orange" dark x-large v-scroll-to="'#video'">
                                                Видеопрезентация
                                                <v-icon dark large class="ml-3">mdi-video</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-img>
        -->
        <vc-gift-banner class="mt-0">
        </vc-gift-banner>
        <v-container>
            <!--
                        <v-row
                                align="start"
                                justify="center"
                        >
                            <v-col>
                                <v-alert
                                        text
                                        outlined
                                        color="deep-orange"
                                        icon="mdi-fire"
                                        prominent
                                        transition="scale-transition"
                                        class="mx-12 mt-12"
                                >
                                    <v-row>
                                        <v-col class="grow ml-5">
                                            <p class="h11-text">32 занятия по английскому по цене одного урока с репетитором!</p>
                                            <p class="h22-text mt-2">Завершается набор в промо-группу. Осталось еще <v-chip color="deep-orange" class="h33-text white&#45;&#45;text font-weight-bold" dark>2 места</v-chip></p>
                                        </v-col>
                                        <v-col class="shrink d-none d-lg-flex" align-self="center">
                                            <v-btn class="mr-10" color="deep-orange" dark x-large href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii">Узнать больше</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>
            -->
            <v-row
                    align="stretch"
                    justify="space-around"
                    class="my-10"
            >
                <v-col
                >
                    <v-card
                            height="100%"
                            outlined
                            color="#F6F6F6"
                    >
                        <v-img
                                :src="require('../assets/back.png')"
                        >
                            <div
                                    class="bg-blue"
                            >
                            </div>
                            <v-card-title class="list-header-text py-4 pl-6">
                                Ключевые особенности обучающей системы
                            </v-card-title>
                            <v-card-text>
                                <v-list class="transparent">
                                    <v-list-item
                                    >
                                        <v-list-item-icon>
                                            <v-icon x-large color="green">mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="list-text">Проверенный годами натуральный подход</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                    >
                                        <v-list-item-icon>
                                            <v-icon x-large color="green">mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="list-text">Занятия проходят в игровой форме</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                    >
                                        <v-list-item-icon>
                                            <v-icon x-large color="green">mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="list-text">Большой объем языковой практики</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                    >
                                        <v-list-item-icon>
                                            <v-icon x-large color="green">mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="list-text">Дети слушают речь носителей языка</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                    >
                                        <v-list-item-icon>
                                            <v-icon x-large color="green">mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="list-text">Подходит даже "трудным" ученикам</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="mt-5">
            <v-card
                    outlined
            >
                <div
                        class="bg-green"
                >
                </div>
                <v-card-title class="h1-text font-weight-medium text-center justify-center py-6">
                    Сравнить с другими вариантами обучения
                </v-card-title>
                <div
                        class="px-0 pb-3"
                >
                    <v-tabs
                            background-color="transparent"
                            grow
                            v-model="tab"
                            active-class="active-tab"
                            show-arrows
                            class="mb-3"
                    >
                        <v-tab
                        >
                            Языковой кружок
                        </v-tab>
                        <v-tab
                        >
                            Опытный репетитор
                        </v-tab>
                        <v-tab
                        >
                            Известные онлайн-школы
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items
                            v-model="tab"
                    >
                        <v-tab-item
                        >
                            <v-card
                                    flat
                                    width="100%"
                            >
                                <v-row>
                                    <v-col>
                                        <v-card
                                                flat
                                        >
                                            <v-card-title class="pt-0">
                                                Что общего?
                                            </v-card-title>
                                            <v-card-text class="regular-text">
                                                <ul>
                                                    <li>Занятия с преподавателем проходят в небольших группах по расписанию</li>
                                                    <li>Учебный процесс организован в увлекательной игровой форме</li>
                                                    <li>Демократичные цены на обучение</li>
                                                </ul>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col>
                                        <v-card
                                                flat
                                        >
                                            <v-card-title class="pt-0">
                                                Чем отличается?
                                            </v-card-title>
                                            <v-card-text class="regular-text">
                                                <ul>
                                                    <li>Проводим занятия в удобном онлайн-формате, вам никуда не надо вести ребенка</li>
                                                    <li>Предлагаем индивидуальный подход к каждому ученику</li>
                                                    <li>Большую часть учебного времени ребенок слушает речь носителей</li>
                                                    <li>Наши ученики занимаются языком намного больше за счет дополнительных самостоятельных тренингов, выполняемой под полным контролем компьютера</li>
                                                </ul>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item
                        >
                            <v-card
                                    flat
                                    width="100%"
                            >
                                <v-row>
                                    <v-col>
                                        <v-card
                                                flat
                                        >
                                            <v-card-title class="pt-0">
                                                Что общего?
                                            </v-card-title>
                                            <v-card-text class="regular-text">
                                                <ul>
                                                    <li>Постоянный контроль усвоения материала гарантирует высокую эффективность обучения</li>
                                                    <li>Возможен учет индивидуальных особенностей каждого ребенка</li>
                                                    <li>Ученик быстро прогрессирует за счет движения по оптимальной траектории обучения</li>
                                                </ul>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col>
                                        <v-card
                                                flat
                                        >
                                            <v-card-title class="pt-0">
                                                Чем отличается?
                                            </v-card-title>
                                            <v-card-text class="regular-text">
                                                <ul>
                                                    <li>Проводим занятия в увлекательной игровой форме</li>
                                                    <li>Мы гораздо надежнее - никуда не пропадем, не откажемся от "неудобного" ребенка, не будем делать постоянных отмен и переносов</li>
                                                    <li>Нашим ученикам не нужны книжки и тетрадки - все материалы встроены в платформу</li>
                                                    <li>Стоимость учебного времени в нашей школе в разы ниже, чем у любого репетитора</li>
                                                </ul>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item
                        >
                            <v-card
                                    flat
                                    width="100%"
                            >
                                <v-row>
                                    <v-col>
                                        <v-card
                                                flat
                                        >
                                            <v-card-title class="pt-0">
                                                Что общего?
                                            </v-card-title>
                                            <v-card-text class="regular-text">
                                                <ul>
                                                    <li>Серьезный бизнес, который может себе позволить иметь уникальные методические наработки, эксклюзивный образовательный контент, обширный преподавательский состав</li>
                                                    <li>Имеется собственная интернет-платформа, автоматизирующая учебный процесс</li>
                                                    <li>Ребенка обучает не просто преподаватель, а сложная система, состоящая из многих компонентов</li>
                                                    <li>Процесс обучения полностью прозрачен для родителей</li>
                                                </ul>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col>
                                        <v-card
                                                flat
                                        >
                                            <v-card-title class="pt-0">
                                                Чем отличается?
                                            </v-card-title>
                                            <v-card-text class="regular-text">
                                                <ul>
                                                    <li>Используем натуральный подход, идеально подходящий для обучения детей</li>
                                                    <li>Делаем упор на самом сложном и ценном - формировании глубокого и долгосрочного владения разговорным языком</li>
                                                    <li>Работаем по уникальной игровой методике, создающей атмосферу соревнований</li>
                                                    <li>Наши ученики занимаются ежедневно и под полным контролем, что обеспечивает быстрое и надежное усвоение языка</li>
                                                    <li>У нас найдется тарифный план под любой семейный бюджет</li>
                                                </ul>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-card>
        </v-container>
        <v-container fluid class="mb-0 mt-11 light-bg">
            <v-row
                    class="mb-0 mt-3"
            >
                <v-col
                        class="text-center h0-text"
                >
                    Предлагаем лучшие цены на английский для детей
                </v-col>
            </v-row>
            <vc-pricing-banner class="py-0"></vc-pricing-banner>
            <v-container class="pt-0 pb-5">
                <v-row
                        class="mb-0 mt-0"
                >
                    <v-col
                            class="text-center"
                    >
                        <v-btn text x-large href="/ceny-na-onlajn-kursy-anglijskogo-yazyka">[ Подробнее о ценах ]</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <!--
                <vc-cheap-banner class="mt-0">
                    <v-btn class="mt-2 mb-2" color="deep-orange" dark x-large href="/anglijskij-yazyk-dlya-detej-besplatno">
                        Узнать больше
                    </v-btn>
                </vc-cheap-banner>
        -->
        <!--
                    <v-row
                            class="mt-2"
                    >
                        <v-col
                                class="text-center"
                        >
                            <v-btn text x-large :to="{ name: 'pricing' }">[ Подробнее о ценах и опциях ]</v-btn>
                        </v-col>
                    </v-row>
        -->
        <!--<v-divider class="mt-13"></v-divider>-->
        <v-container fluid class="dark-bg">
            <v-container>
                <v-row
                        class="mb-0 mt-3"
                >
                    <v-col
                            class="text-center h0-white-text"
                    >
                        Подробнее о технологии обучения
                    </v-col>
                </v-row>
                <v-row
                        class="my-9"
                        id="video"
                >
                    <v-col
                            cols="12"
                            md="5"
                    >
                        <!--<p class="h2-white-text mb-3">Самая модная в этом сезоне</p>-->
                        <p class="h1-white-text mb-10">Видеопрезентация обучающей системы</p>
                        <div class="embed-container">
                            <iframe src='https://www.youtube.com/embed/Q-JS_G22oqg' frameborder='0' allowfullscreen>
                            </iframe>
                        </div>
                        <v-btn color="deep-orange" class="mt-12" dark x-large @click.stop="dialog = true">Попробовать бесплатно</v-btn>
                    </v-col>
                    <v-col
                            cols="12"
                            md="6"
                            offset-md="1"
                    >
                        <v-list
                                flat
                                color="#595959"
                        >
                            <v-list-item class="mb-5">
                                <v-list-item-icon class="hidden-xs-only">
                                    <v-avatar color="yellow darken-1" size="64">
                                        <v-icon x-large dark>mdi-gamepad-variant-outline</v-icon>
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <p class="h2-white-text mb-3">
                                        Интуитивная игровая методика нового поколения
                                    </p>
                                    <ul>
                                        <li>
                                            Опирается на природные механизмы психики
                                        </li>
                                        <li>
                                            Обеспечивает глубинное усвоение языка
                                        </li>
                                        <li>
                                            Не травмирует ребенка
                                        </li>
                                        <li>
                                            Подходит практически каждому
                                        </li>
                                    </ul>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mb-5">
                                <v-list-item-icon class="hidden-xs-only">
                                    <v-avatar color="purple darken-3" size="64">
                                        <v-icon x-large dark>mdi-server</v-icon>
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <p class="h2-white-text mb-3">
                                        Интеллектуальная платформа управления обучением
                                    </p>
                                    <ul>
                                        <li>
                                            Низкая стоимость учебного часа
                                        </li>
                                        <li>
                                            100% контроль усвоения материала
                                        </li>
                                        <li>
                                            Индивидуальный подход к каждому ученику
                                        </li>
                                        <li>
                                            Беспристрастность и объективность
                                        </li>
                                    </ul>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mb-5">
                                <v-list-item-icon class="hidden-xs-only">
                                    <v-avatar color="blue-grey darken-1" size="64">
                                        <v-icon x-large dark>mdi-cellphone-android</v-icon>
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <p class="h2-white-text mb-3">
                                        Уникальный мобильный клиент
                                    </p>
                                    <ul>
                                        <li>
                                            Превращает смартфон в инструмент обучения
                                        </li>
                                        <li>
                                            Позволяет заниматься английским где угодно
                                        </li>
                                        <li>
                                            Обеспечивает контроль усвоения материала
                                        </li>
                                        <li>
                                            Не требует постоянного доступа в Интернет
                                        </li>
                                    </ul>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon class="hidden-xs-only">
                                    <v-avatar color="teal" size="64">
                                        <v-icon x-large dark>mdi-monitor-dashboard</v-icon>
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <p class="h2-white-text mb-3">
                                        Удобный личный кабинет для родителей
                                    </p>
                                    <ul>
                                        <li>
                                            Планируйте видеоуроки с преподавателями
                                        </li>
                                        <li>
                                            Следите за успеваемостью и дисциплиной
                                        </li>
                                        <li>
                                            Получайте доступ к детальной статистике
                                        </li>
                                        <li>
                                            Контролируйте расходы и оплачивайте занятия
                                        </li>
                                    </ul>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                <v-dialog
                        v-model="dialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-toolbar
                                dark
                                dense
                                color="indigo darken-2"
                        >
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn
                                        icon
                                        dark
                                        @click="dialog = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text class="mt-5">
                            <vc-request-form
                                    type="Заявка на промо"
                                    header-icon="mdi-square-edit-outline"
                                    header-text="Заявка на бесплатный курс из 32 занятий"
                                    :request-age="true"
                                    :request-email="true"
                                    :verifyPhone="false">
                            </vc-request-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-container>
        </v-container>
        <v-container>
            <!--
                        <v-row
                                class="mb-0 mt-14"
                                id="video"
                        >
                            <v-col
                                    class="text-center"
                            >
                                <p class="h0-text">Лучше один раз увидеть!</p>
                            </v-col>
                        </v-row>
                        <v-row
                                class="mb-0"
                        >
                            <v-col
                                    class="text-center"
                            >
                                <youtube
                                        video-id="QyLuukHSqPo"
                                        player-width="854"
                                        player-height="480"
                                >

                                </youtube>
                            </v-col>
                        </v-row>
            -->
            <v-row
                    class="mb-0 mt-3"
            >
                <v-col
                        class="text-center"
                >
                    <p class="h0-text">Присоединяйтесь, мы ждем вас!</p>
                </v-col>
            </v-row>
            <v-row
                    align="stretch"
                    justify="center"
                    id="order"
            >
                <v-col
                        cols="12" md="10"
                >
                    <vc-request-form
                            type="Заявка на промо"
                            header-icon="mdi-square-edit-outline"
                            header-text="Заявка на обучение"
                            :request-age="true"
                            :request-email="true"
                            :verifyPhone="false"></vc-request-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import RequestForm from '../components/RequestForm';
    import GiftBanner from '../components/GiftBanner';
    import CheapBanner from '../components/CheapBanner';
    import PricingBanner from '../components/PricingBanner';

    export default {
        data () {
            return {
                tab: null,
                showReason: false,
                dialog: false,
                items: [
                    {
                        src: 'gamer-3.jpg',
                        color: '48,49,88',
                        header: 'Интуитивная игровая методика нового поколения',
                        text: `
                            <ul>
                                <li>
                                    Опирается на природные механизмы психики
                                </li>
                                <li>
                                    Обеспечивает глубинное усвоение языка
                                </li>
                                <li>
                                    Не травмирует ребенка
                                </li>
                                <li>
                                    Подходит практически каждому
                                </li>
                            </ul>
                            `,
                    },
                    {
                        src: 'servers-1.jpg',
                        color: '33,87,73',
                        header: 'Интеллектуальная платформа управления обучением',
                        text: `
                            <ul>
                                <li>
                                    Низкая стоимость учебного часа
                                </li>
                                <li>
                                    100% контроль усвоения материала
                                </li>
                                <li>
                                    Индивидуальный подход к каждому ученику
                                </li>
                                <li>
                                    Беспристрастность и объективность
                                </li>
                            </ul>
                            `,
                    },
                    {
                        src: 'smartphone-1.jpg',
                        color: '84,21,108',
                        header: 'Мобильный клиент с уникальными возможностями',
                        text: `
                            <ul>
                                <li>
                                    Превращает смартфон в инструмент обучения
                                </li>
                                <li>
                                    Позволяет заниматься английским где угодно
                                </li>
                                <li>
                                    Обеспечивает контроль усвоения материала
                                </li>
                                <li>
                                    Не требует постоянного доступа в Интернет
                                </li>
                            </ul>
                            `,
                    },
                    {
                        src: 'parents-1.jpg',
                        color: '102,32,59',
                        header: 'Удобный личный кабинет для родителей',
                        text: `
                            <ul>
                                <li>
                                    Планируйте видеоуроки с преподавателями
                                </li>
                                <li>
                                    Следите за успеваемостью и дисциплиной
                                </li>
                                <li>
                                    Получайте доступ к детальной статистике
                                </li>
                                <li>
                                    Контролируйте расходы и оплачивайте занятия
                                </li>
                            </ul>
                            `,
                    },
                ],
            }
        },

        components: {
            'vc-request-form': RequestForm,
            'vc-gift-banner': GiftBanner,
            'vc-pricing-banner': PricingBanner,
        },

        mounted() {
        },

        computed: {
            promptText() {
                return this.showReason ? 'свернуть' : 'почему это важно';
            },
        },

        methods: {
            invertPrompt() {
                this.showReason = !this.showReason;
            },

            gradient(color) {
                return `height: 100%; background-image: linear-gradient(90deg, rgba(${color},0.95) 0%, rgba(${color},0.8) 60%, rgba(${color},0.6) 100%)`;
            },
        },
    };
</script>

<style scoped>
    .normal-word-break {
        word-break: normal;
    }
    .v-sheet {
        border-radius: 8px;
    }
    .blur {
        filter: blur(14px);
    }
    .dark-bg li {
        font-size: 1.1rem !important;
        line-height: 1.6rem !important;
        font-weight: 400 !important;
        color: #FFF !important;
        word-break: normal !important;
    }
    .regular-text li {
        margin-bottom: 11px;
    }
    .stroked {
        text-shadow: 0px 0px 3px #000000 !important;
    }
    .gray-border {
        border-color: #d0d0d0 !important;
    }
    .mt-13 {
        margin-top: 85px;
    }
    .mt-14 {
        margin-top: 100px;
    }
    .mb-14 {
        margin-bottom: 100px;
    }
    .px-14 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .rcorners {
        border-radius: 7px;
    }
    .dark-bg {
        background-color: #595959;
    }
    .light-bg {
        background-color: #F6F6F6;
    }
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }
    .embed-container iframe, .embed-container object, .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    strong {
        font-weight: 800 !important;
    }
    ul li::marker {
        font-size: 1.5em;
    }
    .bg-blue {
        background-color: #d7d8e6;
        height: 18px;
    }
    .bg-green {
        background-color: #cdead2;
        height: 18px;
    }
    .bg-yellow {
        background-color: #f7d9ee;
        height: 18px;
    }
    .prompt-text {
        font-size: 0.9em;
    }
    .dotted {
        border-bottom: 1px dotted blue;
        text-decoration: none;
    }
    .fixed-space {
        min-height: 30px;
    }
    .list-header-text {
        font-size: 2.3rem !important;
        line-height: 2.6rem !important;
        font-weight: 600 !important;
        color: #595959 !important;
    }
    .list-text {
        font-size: 1.8rem !important;
        line-height: 2rem !important;
        font-weight: 500 !important;
        color: #595959 !important;
        word-break: normal !important;
        white-space: normal !important;
    }
</style>