<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-container class="promo-bg py-0" fluid>
            <v-row
                    align="center"
                    justify="center"
                    id="promo-container"
            >
                <v-col
                        class="pt-0 pb-0 hidden-sm-and-down"
                >
                    <div class="d-flex justify-center fill-width">
                        <img
                                :src="require('../assets/selfteaching3.jpg')"
                                width="480px"
                                height="460px"
                        >
                    </div>
                </v-col>
                <v-col
                >
                    <v-card
                            flat
                            color="rgba(255, 255, 255, 0)"
                    >
                        <v-card-text class="red-text white--text pb-0">
                            Интерактивный самоучитель
                        </v-card-text>
                        <v-card-text class="headline-text">
                            50 занятий<br>БЕСПЛАТНО
                        </v-card-text>
                        <v-card-text class="text-center">
                            <slot>
                                <v-btn class="mt-2 mb-2" color="deep-orange" dark x-large v-scroll-to="'#order'">
                                    Оформить заявку
                                </v-btn>
                            </slot>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row
                    class="mb-0 mt-8"
                    id="promo"
            >
                <v-col
                >
                    <div class="h2-text">
                        <p>Предлагаем быстрый старт для вашего ребенка в овладении разговорным англйиским. Не требует затрат с вашей стороны! Занимайтесь с ребенком языком при помощи нашего интерактивного самоучителя.</p>
                        <p>Не волнуйтесь, в этом нет ничего сложного. Вам не понадобятся даже базовые познания английского - вы можете учиться вместе с ребенком. Для объяснения нового материала вы сможете использовать видеоролики, созданные нашими преподавателями. Интеллектуальная система управления обучением будет самостоятельно направлять ход учебного процесса.</p>
                        <p>Более того, основную часть тренингов ребенок будет проходить без вашего участия наедине с компьютером, который сам будет формировать для него задания и выставлять оценки. Вам останется лишь контролировать успеваемость и, при необходимости, восполнять выявленные пробелы в знаниях.</p>
                        <p class="pt-10 pb-3 h1-text">Оформите <a href="" v-scroll-to="'#order'">заявку</a> на регистрацию прямо сейчас и получите <span class="important-text">бесплатный</span> доступ к мини-курсу "Разговорный английский для начинающих", включающий <span class="important-text">50 уроков</span> английского по следующей формуле:</p>
                        <v-container class="px-3">
                            <v-row
                                    align="stretch"
                                    justify="space-around"
                            >
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-card
                                            height="100%"
                                            raised
                                    >
                                        <v-card-text class="h0-text pb-3 centered-text">
                                            12
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text class="title-text centered-text">
                                            тематических тренингов
                                            по <span class="nobr">самоучителю <v-tooltip
                                                top
                                                eager
                                                max-width="400px"
                                        >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small v-bind="attrs" v-on="on" class="sup">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>Тематический тренинг - занятие, проводимое в игровой форме с использованием интерактивного самоучителя. Как правило, посвящен освоению новой темы либо контролю усвоения и закреплению ранее пройденного материала. Ученику доступен просмотр роликов с объяснениями преподавателей и индивидуальные игры для закрепления материала.</span>
                                            </v-tooltip>
                                            </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="1"
                                        align-self="center"
                                        class="text-center"
                                >
                                    <v-icon x-large color="grey darken-1">mdi-plus-circle</v-icon>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-card
                                            height="100%"
                                            raised
                                    >
                                        <v-card-text class="h0-text pb-3 centered-text">
                                            12
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text class="title-text centered-text">
                                            игровых тренингов на <span class="nobr">компьютере <v-tooltip
                                                top
                                                max-width="400px"
                                                eager
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on" class="sup">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Игровой тренинг - индивидуальное упражнение, выполняемое учеником на компьютере и предназначенное для расширения вокабуляра, а также отработки навыков распознавания речи на слух. В процессе обучения используются элементы компьютерных игр, как простейших (различные виды квизов), так и довольно сложных (квесты и прочие аркадные игры).</span>
                                        </v-tooltip>
                                        </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="1"
                                        align-self="center"
                                        class="text-center"
                                >
                                    <v-icon x-large color="grey darken-1">mdi-plus-circle</v-icon>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-card
                                            height="100%"
                                            raised
                                    >
                                        <v-card-text class="h0-text pb-3 centered-text">
                                            26
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text class="title-text centered-text">
                                            листенинг-тренингов на компьютере
                                            или <span class="nobr">смартфоне <v-tooltip
                                                top
                                                eager
                                                max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on" class="sup">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Листенинг-тренинг - индивидуальное упражнение на прослушивание, выполняемое учеником на компьютере или смартфоне и предназначенное для тренировки навыков понимания беглой речи, начитанной носителями английского языка. По ходу упражнения система постоянно контролирует внимание ученика и оценивает степень понимания аудиоматериала при помощи контрольных вопросов. Учебные материалы упакованы в мультимедийный формат и, как правило, представляют собой мультфильмы.</span>
                                        </v-tooltip>
                                        </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="mt-12">
                                <v-col>
                                    <v-alert
                                            text
                                            outlined
                                            color="deep-orange"
                                            icon="mdi-alert"
                                            class="regular-text"
                                            prominent
                                            id="order"
                                    >
                                        <v-row>
                                            <v-col class="grow ml-5">
                                                <p class="h11-text">Нет возможности заниматься с ребенком самостоятельно?</p>
                                                <!--<p class="h22-text mt-2">Предлагаем курс занятий с преподавателем по <v-chip color="deep-orange" class="title font-weight-bold" dark><v-icon left>mdi-fire</v-icon>СУПЕРЦЕНЕ</v-chip></p>-->
                                                <p class="h22-text mt-2">Предлагаем курс занятий с преподавателем!</p>
                                            </v-col>
                                            <v-col class="shrink d-none d-lg-flex" align-self="center">
                                                <v-btn class="mr-10" color="deep-orange" dark x-large href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii">Узнать больше</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
            <v-row
                    class="mb-0"
            >
                <v-col
                        class="text-center"
                >
                    <p class="mt-0 h0-text">Зарегистрируйтесь для доступа к курсу</p>
                </v-col>
            </v-row>
            <v-row
                    align="stretch"
                    justify="center"
                    class="mt-2"
            >
                <v-col
                        cols="12"
                        md="10"
                >
                    <v-card
                            height="100%"
                            raised
                    >
                        <v-card-title class="h2-text bg-darken font-weight-medium pb-3 mb-5">
                            <v-icon large class="mr-2">mdi-square-edit-outline</v-icon>
                            Шаг 1. Укажите контактную информацию
                        </v-card-title>
                        <v-card-text>
                            <vc-request-form type="Активация бесплатного пробного периода" :request-age="true" :request-email="true" actionText="Далее"></vc-request-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import RequestForm from '../components/RequestForm';

    export default {
        data () {
            return {
            }
        },

        components: {
            'vc-request-form': RequestForm,
        },

        mounted() {
        },
    };
</script>

<style scoped>
    .v-sheet {
        border-radius: 8px;
    }
    ul, ol {
        margin-bottom: 1.5rem;
    }
    li {
        margin-bottom: 0.5rem;
    }
    .important-text {
        color: orangered !important;
        font-weight: 800 !important;
    }
    .orange-text {
        color: orangered !important;
    }
    .orange-border {
        border-color: orangered !important;
    }
    .centered-text {
        text-align: center !important;
    }
    .promo-bg {
        background-color: #7cb2ac;
    }
    .fill-width {
        width: 100%;
    }
    #promo-container {
        min-height: 460px;
    }
    a {
        text-decoration: none;
    }
</style>