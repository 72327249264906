<template>
    <v-container class="promo-bg py-0" fluid>
        <v-row
                align="center"
                justify="center"
                id="promo-container-sm"
                class="hidden-md-and-up"
        >
            <v-col
                    class="pt-0 pb-0 hidden-xs-only"
                    sm="6"
            >
                <v-img
                        :src="require('../assets/promo77.jpg')"
                        max-height="300px"
                        contain
                ></v-img>
            </v-col>
            <v-col
                    sm="6"
            >
                <v-card
                        flat
                        color="rgba(255, 255, 255, 0)"
                >
                    <v-card-text class="banner2-text py-0">
                        Английский с преподавателем
                    </v-card-text>
                    <v-card-text class="banner1-text py-2">
                        от 990 руб/мес
                    </v-card-text>
                    <v-card-text class="banner2-text">
                        Начните обучение прямо сейчас бесплатно
                    </v-card-text>
                    <v-card-text class="text-center pt-3 pb-0">
                        <slot>
                            <v-btn class="mr-2" color="indigo darken-3" dark @click.stop="dialog = true">
                                Получить 32 занятия в подарок
                            </v-btn>
<!--
                            <v-btn class="" text color="indigo darken-3" large href="/anglijskij-yazyk-dlya-detej-besplatno">
                                Узнать больше
                            </v-btn>
-->
                        </slot>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
                align="center"
                justify="center"
                id="promo-container"
                class="hidden-sm-and-down"
        >
            <v-col
                    class="pt-10 pb-0 hidden-sm-and-down"
            >
                    <v-img
                            :src="require('../assets/promo77.jpg')"
                            max-height="420px"
                            contain
                    ></v-img>
            </v-col>
            <v-col
            >
                <v-card
                        flat
                        color="rgba(255, 255, 255, 0)"
                >
                    <v-card-text class="banner1-text hidden-sm-and-down pb-1">
                        Английский с преподавателем
                    </v-card-text>
                    <v-card-text class="super-headline-text hidden-sm-and-down">
                        от 990 руб/мес
                    </v-card-text>
                    <v-card-text class="banner1-text mt-5">
                        Начните обучение прямо сейчас бесплатно
                    </v-card-text>
                    <v-card-text class="text-center">
                        <slot>
                            <v-btn class="mt-2 mr-2 xxl-button pa-8" color="indigo darken-3" dark x-large @click.stop="dialog = true">
                                Получить 32 занятия в подарок
                            </v-btn>
<!--                            <v-btn class="mt-2" text color="indigo darken-3" x-large href="/anglijskij-yazyk-dlya-detej-besplatno">
                                Узнать больше
                            </v-btn>-->
                        </slot>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                        dark
                        dense
                        color="indigo darken-2"
                >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                                icon
                                dark
                                @click="dialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <vc-request-form
                            type="Заявка на промо"
                            header-icon="mdi-square-edit-outline"
                            header-text="Заявка на бесплатный курс из 32 занятий"
                            :request-age="true"
                            :request-email="true"
                            :verifyPhone="false">
                    </vc-request-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import RequestForm from '../components/RequestForm';

    export default {
        name: "GiftBanner",

        components: {
            'vc-request-form': RequestForm,
        },

        data() {
            return {
                dialog: false,
            };
        },
        computed: {
            dueDate() {
                const tomorrow = new Date();
                tomorrow.setDate(new Date().getDate() + 7);
                const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
                    "июля", "августа", "сентября", "октября", "ноября", "декабря"
                ];
                return tomorrow.getDate() + ' ' + monthNames[tomorrow.getMonth()];
            },
        },
    }
</script>

<style scoped>
    .promo-bg {
        background-color: #f1eeee;
    }
    .fill-width {
        width: 100%;
    }
    #promo-container {
        min-height: 460px;
    }
    #promo-container-sm {
        min-height: 300px;
    }
    .xxl-button {
        font-size: 1.3rem;
    }
    .banner1-text {
        font-size: 2rem !important;
        line-height: 2.2rem !important;
        font-weight: 800 !important;
        color: #1A237E !important;
        text-align: center !important;
    }
    .banner2-text {
        font-size: 1.3rem !important;
        line-height: 1.5rem !important;
        font-weight: 600 !important;
        color: #1A237E !important;
        text-align: center !important;
    }
</style>