<template>
    <v-container>
        <v-row
                align="center"
                justify="center"
        >
            <v-col class="text-center">
                <p class="mt-3 mb-0 h0-text">Прогресс в обучении день за днем</p>
            </v-col>
        </v-row>
        <v-row
                align="center"
                class="mx-0"
        >
            <v-col
            >
                <v-timeline>
                    <vc-plan-item
                            v-for="(course, i) in plan"
                            :key="i"
                            :course="course"
                            >
                    </vc-plan-item>
                </v-timeline>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p class="h3-text">Описание уровней</p>
                <p><strong>L1</strong> - Только базовые грамматических конструкции. Необходимый словарный запас 100 - 200 слов.</p>
                <p><strong>L2</strong> - Грамматические конструкции простые, но уже в разных временах. Знакомство с некоторыми фразовыми глаголами. Необходимый словарный запас 300 - 500 слов.</p>
                <p><strong>L3</strong> - Более сложные грамматические конструкции. Больше фразовых глаголов. Необходимый словарный запас 700 - 1000&nbsp;слов.</p>
                <p><strong>L4</strong> - Широкий набор грамматических конструкций. Сложные предложения. Необходимый словарный запас 1300 - 2000&nbsp;слов.</p>
                <p><strong>L5</strong> - Практически полный набор грамматических конструкций. Использование идиом. Необходимый словарный запас более 2000&nbsp;слов.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import PlanItem from '../components/PlanItem';

    export default {
        components: {
            'vc-plan-item': PlanItem,
        },

        data () {
            return {
                tab: null,
                plan1: [
                    {
                        title: 'Первые слова',
                        description: 'Для тех, кто начинает с "нуля". Закладывается фундамент курса: простые конструкции и выражения, необходимый базовый набор лексики. Ребенок приучается к ежедневным занятиям языком и соблюдению учебной дисциплины.',
                        comment: 'Первый год обучения',
                        results: {
                            listen: 'Можем понимать на слух мини-диалоги на 10 различных тем. Английская речь уже не кажется нам незнакомой.',
                            speak: null,
                            read: null,
                            dictionary: {
                                p: '100 - 120',
                                a: '5 - 10',
                            },
                        },
                        stages: [
                            [
                                'Понимание вопросов об имени.',
                                'Понимание базовых понятий - "я / ты", цвета, цифры, "мой / твой".',
                                'Глагол "to be".',
                            ],
                            [
                                'Понимание фраз с личными местоимениями.',
                                'Понимание фраз о возрасте.',
                                'Понимание вопросов типа "сколько?", "кто?", "что?", "где?"',
                                'Простые просьбы ("дай мне", "положи", "найди" и т.п.).',
                            ],
                            [
                                'Понимание различных характеристик (большой - маленький, новый - старый).',
                                'Выражение отношения ("нравится / не нравится", "хочу / не хочу").',
                            ],
                            [
                                'Выражения с различными действиями, "могу / не могу".',
                                'Названия погодных явлений и времен года. Характеристики погодных явлений.',
                            ],
                            [
                                'Понимание названий диких животных, их базовые характеристики (размер, цвет, место проживания).',
                                'Понимание названий членов семьи.',
                                'Части суток и их характеристики (светло - темно).',
                            ],
                        ],
                    },
                    {
                        title: 'В гостях у сказки',
                        description: 'Для тех, кто уже не новичок и понимает несложные фразы с базовой лексикой. Развиваются навыки понимания на слух аудио-сказок. Усложняется лексика и языковые конструкции. Отрабатываются первые навыки говорения.',
                        comment: null,
                        results: {
                            listen: 'Можем понимать на слух аудио-сказки уровня сложности L1 - L2 (10 сказок). Знакомые сказки в английском звучании - иностранный язык кажется знакомым.',
                            speak: 'Можем сказать несколько фраз на 5 тем. Сказка постепенно становится реальностью.',
                            read: null,
                            dictionary: {
                                p: '300 - 400',
                                a: '60 - 100',
                            },
                        },
                        stages: [
                            [
                                'Прямая и косвенная речь. Просьба помочь что-либо сделать.',
                                'Расширение списка фразовых глаголов.',
                            ],
                            [
                                'Понимание сложных предложений с союзами "and" и "but".',
                                'Описание внешности.',
                                'Понимание некоторых выражений в прошедшем времени (simple past).',
                            ],
                            [
                                'Понимание форм -ing. Сравнительная и превосходная форма прилагательных.',
                                'Понимание слов "this", "that", "these", "those".',
                            ],
                        ],
                    },
                    {
                        title: 'Давай поговорим!',
                        description: 'Для тех, кто хочет слушать и смотреть "неадаптированный" материал, пусть и для более младшего возраста. Начинаем интенсивно говорить. Этап "все понимаю, а сказать не могу" уже позади.',
                        comment: 'Второй год обучения',
                        results: {
                            listen: 'Можем смотреть мультики для дошкольного возраста в оригинале.',
                            speak: 'Мы уже полноправные участники диалога на темы основных интересов сверстников.',
                            read: 'Можем прочитать что-то простое.',
                            dictionary: {
                                p: '1200 - 1800',
                                a: '300 - 500',
                            },
                        },
                        stages: [
                            [
                                'Понимание текстов с простыми формами настоящего и прошедшего времени.',
                                'Знакомство с написанием слов.',
                                'Диалоги на темы "отдых на природе", "хобби".',
                            ],
                            [
                                'Понимание текстов с более сложными формами настоящего и прошедшего времени (perfect).',
                                'Знакомство с написанием слов.',
                                'Диалоги на темы "здоровье", "занятие спортом".',
                            ],
                            [
                                'Понимание текстов с более сложными формами будущего времени. Фразы с "going to".',
                                'Диалоги на темы "музыка", "кино".',
                                'Переход от чтения слов к чтению предложений.',
                            ],
                            [
                                'Понимание текстов с формами выражения необходимости ("must", "have to", "could")',
                                'Усложнение заданий на чтение.',
                                'Диалоги на темы "фантастика и мистика", "праздники".',
                            ],
                            [
                                'Понимание будущего времени с условием ("if", "what if").',
                                'Диалоги на темы "друзья", "хорошие манеры".',
                                'Переход от чтения предложений к чтению текстов.',
                            ],
                        ],
                    },
                    {
                        title: 'Прочитаю - расскажу',
                        description: 'Для тех, кто уже хорошо понимает речь на слух, но хочет развиваться дальше, расширить темы для разговоров, выйти из образа "молчуна". Дополнительный курс чтения не только поможет в развитии навыков, но и обогатит язык.',
                        comment: 'Третий год обучения',
                        results: {
                            listen: 'Можем смотреть мультфильмы и детские фильмы в оригинале.',
                            speak: 'Мы уже полноправные участники диалога. Не боимся разговаривать на темы основных интересов сверстников.',
                            read: 'Можем читать адаптированную детскую литературу.',
                            dictionary: {
                                p: '3000 - 5000',
                                a: '1500 - 2500',
                            },
                        },
                        stages: [
                            [
                                'Понимание "often", "always", "usually", "sometimes", "never".',
                                'Диалоги на темы "магазины и покупки", "еда и кафе".',
                                'Чтение простых текстов L2.',
                            ],
                            [
                                'Понимание текстов с более сложными формами настоящего и прошедшего времени (present perfect continuous).',
                                'Диалоги на темы "путешествия", "обсуждение книг".',
                                'Чтение простых текстов L2.',
                            ],
                            [
                                'Диалоги на темы  "окружающий мир", "опасности".',
                                'Чтение текстов L3.',
                            ],
                            [
                                'Закрепление понимания фраз в различных грамматических формах.',
                                'Диалоги на темы "исторические события", "музеи и достопримечательности".',
                                'Чтение текстов L4.',
                            ],
                            [
                                'Закрепление понимания фраз в различных грамматических формах.',
                                'Диалоги на темы "образование и университеты", "профессии".',
                                'Чтение текстов L4 - L5.',
                            ],
                        ],
                    },
                ],
                plan2: [
                    {
                        title: 'Первые слова',
                        description: 'Для тех, кто начинает с "нуля". Закладывается фундамент курса: простые конструкции и выражения, необходимый базовый набор лексики. Ребенок приучается к ежедневным занятиям языком и соблюдению учебной дисциплины.',
                        comment: '',
                        results: {
                            listen: 'Можем понимать на слух мини-диалоги на 10 различных тем. Английская речь уже не кажется нам незнакомой.',
                            speak: null,
                            read: null,
                            dictionary: {
                                p: '100 - 120',
                                a: '5 - 10',
                            },
                        },
                        stages: [
                            [
                                'Понимание вопросов об имени.',
                                'Понимание базовых понятий - "я / ты", цвета, цифры, "мой / твой".',
                                'Глагол "to be".',
                            ],
                            [
                                'Понимание фраз с личными местоимениями.',
                                'Понимание фраз о возрасте.',
                                'Понимание вопросов типа "сколько?", "кто?", "что?", "где?"',
                                'Простые просьбы ("дай мне", "положи", "найди" и т.п.).',
                            ],
                            [
                                'Понимание различных характеристик (большой - маленький, новый - старый).',
                                'Выражение отношения ("нравится / не нравится", "хочу / не хочу").',
                            ],
                            [
                                'Выражения с различными действиями, "могу / не могу".',
                                'Названия погодных явлений и времен года. Характеристики погодных явлений.',
                            ],
                            [
                                'Понимание названий диких животных, их базовые характеристики (размер, цвет, место проживания).',
                                'Понимание названий членов семьи.',
                                'Части суток и их характеристики (светло - темно).',
                            ],
                        ],
                    },
                    {
                        title: 'В гостях у сказки',
                        description: 'Для тех, кто уже не новичок и понимает несложные фразы с базовой лексикой. Развиваются навыки понимания на слух аудио-сказок. Усложняется лексика и языковые конструкции. Отрабатываются первые навыки говорения.',
                        comment: null,
                        results: {
                            listen: 'Можем понимать на слух аудио-сказки уровня сложности L1 - L2 (10 сказок). Знакомые сказки в английском звучании - иностранный язык кажется знакомым.',
                            speak: 'Можем сказать несколько фраз на 5 тем. Сказка постепенно становится реальностью.',
                            read: null,
                            dictionary: {
                                p: '300 - 400',
                                a: '60 - 100',
                            },
                        },
                        stages: [
                            [
                                'Прямая и косвенная речь. Просьба помочь что-либо сделать.',
                                'Расширение списка фразовых глаголов.',
                            ],
                            [
                                'Понимание сложных предложений с союзами "and" и "but".',
                                'Описание внешности.',
                                'Понимание некоторых выражений в прошедшем времени (simple past).',
                            ],
                            [
                                'Понимание форм -ing. Сравнительная и превосходная форма прилагательных.',
                                'Понимание слов "this", "that", "these", "those".',
                            ],
                        ],
                    },
                    {
                        title: 'Говорите. - Я слушаю.',
                        description: 'Для тех, кто уже "вырос" из простых сказок и хочет понимать несложные истории на близкую ребенку тематику. Тренируется умение вести простой диалог, в основном, в роли слушателя.',
                        comment: '',
                        results: {
                            listen: 'Можем понимать на слух адаптированные истории уровня сложности L3 (20 историй). Это уже не сказки, это серьезней.',
                            speak: 'Можем поддержать беседу на 10 различных тем.',
                            read: null,
                            dictionary: {
                                p: '800 - 1000',
                                a: '200 - 300',
                            },
                        },
                        stages: [
                            [
                                'Понимание фраз в прошедшем времени.',
                                'Понимание конструкций "why - because".',
                            ],
                            [
                                'Закрепление понимания форм continuous.',
                                'Неправильные глаголы.',
                            ],
                            [
                                'Закрепление понимания выражений с предлогами места. Вопросы "Where?"',
                                'Умение выражать свое мнение.',
                            ],
                            [
                                'Понимание текстов с фразами о времени. Вопросы "When?"',
                                'Одновременные действия ("while").',
                            ],
                            [
                                'Понимание текстов с фразами в будущем времени.',
                                'Понимание всех типов вопросов.',
                            ],
                        ],
                    },
                    {
                        title: 'Давай поговорим!',
                        description: 'Для тех, кто хочет слушать и смотреть "неадаптированный" материал, пусть и для более младшего возраста. Начинаем интенсивно говорить. Этап "все понимаю, а сказать не могу" уже позади.',
                        comment: '',
                        results: {
                            listen: 'Можем смотреть мультики для дошкольного возраста в оригинале.',
                            speak: 'Мы уже полноправные участники диалога на темы основных интересов сверстников.',
                            read: 'Можем прочитать что-то простое.',
                            dictionary: {
                                p: '1400 - 2000',
                                a: '500 - 600',
                            },
                        },
                        stages: [
                            [
                                'Понимание текстов с более сложными формами настоящего и прошедшего времени.',
                                'Знакомство с написанием слов.',
                                'Диалоги на темы "отдых на природе", "хобби".',
                            ],
                            [
                                'Понимание текстов с более сложными формами настоящего и прошедшего времени (perfect).',
                                'Знакомство с написанием слов.',
                                'Диалоги на темы "здоровье", "занятие спортом".',
                            ],
                            [
                                'Понимание текстов с более сложными формами будущего времени. Фразы с "going to".',
                                'Диалоги на темы "музыка", "кино".',
                                'Переход от чтения слов к чтению предложений.',
                            ],
                            [
                                'Понимание текстов с формами выражения необходимости ("must", "have to", "could")',
                                'Усложнение заданий на чтение.',
                                'Диалоги на темы "фантастика и мистика", "праздники".',
                            ],
                            [
                                'Понимание будущего времени с условием ("if", "what if").',
                                'Диалоги на темы "друзья", "хорошие манеры".',
                                'Переход от чтения предложений к чтению текстов.',
                            ],
                        ],
                    },
                    {
                        title: 'Прочитаю - расскажу',
                        description: 'Для тех, кто уже хорошо понимает речь на слух, но хочет развиваться дальше, расширить темы для разговоров, выйти из образа "молчуна". Дополнительный курс чтения не только поможет в развитии навыков, но и обогатит язык.',
                        comment: '',
                        results: {
                            listen: 'Можем смотреть мультфильмы и детские фильмы в оригинале.',
                            speak: 'Мы уже полноправные участники диалога. Не боимся разговаривать на темы основных интересов сверстников.',
                            read: 'Можем читать адаптированную детскую литературу.',
                            dictionary: {
                                p: '2500 - 3000',
                                a: '1000 - 1200',
                            },
                        },
                        stages: [
                            [
                                'Понимание "often", "always", "usually", "sometimes", "never".',
                                'Диалоги на темы "магазины и покупки", "еда и кафе".',
                                'Чтение простых текстов L2.',
                            ],
                            [
                                'Понимание текстов с более сложными формами настоящего и прошедшего времени (present perfect continuous).',
                                'Диалоги на темы "путешествия", "обсуждение книг".',
                                'Чтение простых текстов L2.',
                            ],
                            [
                                'Диалоги на темы  "окружающий мир", "опасности".',
                                'Чтение текстов L3.',
                            ],
                            [
                                'Закрепление понимания фраз в различных грамматических формах.',
                                'Диалоги на темы "исторические события", "музеи и достопримечательности".',
                                'Чтение текстов L3.',
                            ],
                            [
                                'Закрепление понимания фраз в различных грамматических формах.',
                                'Диалоги на темы "образование и университеты", "профессии".',
                                'Чтение текстов L3.',
                            ],
                        ],
                    },
                    {
                        title: 'В самостоятельное плавание',
                        description: 'Для тех, кто в понимании речи уже "профессионал", которому требуются лишь тренировки для поддержания формы. Самостоятельные занятия в виде просмотра мультиков, прослушивания аудио-книг уже дают основной эффект. Упор делается на развитие навыков чтения.',
                        comment: '',
                        results: {
                            listen: 'Поддержание формы.',
                            speak: 'Поддержание формы.',
                            read: 'Можем читать неадаптированную современную детскую литературу.',
                            dictionary: {
                                p: '3500 - 5000',
                                a: '1800 - 2500',
                            },
                        },
                        stages: [
                            [
                                'Самостоятельные упражнения на прослушивание.',
                                'Упражнения на говорение по запросу.',
                                'Развитие навыков чтения. Чтение текстов L4.',
                            ],
                            [
                                'Дальнейшее развитие навыков чтения. Чтение текстов L5.',
                            ],
                        ],
                    },
                ],
            }
        },

        computed: {
          plan() {
            return this.plan2;
          },
        },

        mounted() {
            // this.plan = this.plan1;
        },
    };
</script>

<style scoped>
    p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: #595959;
    }
</style>