<template>
    <v-container>
        <v-row
                align="center"
                justify="center"
        >
            <v-col class="text-center">
                <p class="mt-3 h0-text">Пользовательское соглашение</p>
            </v-col>
        </v-row>
        <v-row
        >
            <v-col>
                <p>Настоящее Соглашение определяет условия использования Пользователями материалов и сервисов сайта&nbsp;https://loadstarschool.com&nbsp;(далее&nbsp;&mdash; "Сайт").</p>
                <p>Сайт предназначен для информирования Пользователей об услугах в области онлайн-обучения, предоставляемых международной школой английского языка Loadstar.School, а также для регистрации заявок от Пользователей на обучение в данной школе.</p>
                <p><strong>1. Общие условия</strong></p>
                <ol>
                    <li>Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.</li>
                    <li>Сайт не является средством массовой информации.</li>
                    <li>Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Пользователь считается присоединившимся к настоящему Соглашению.</li>
                </ol>
                <p><strong>2. Пользователь имеет право</strong></p>
                <ol>
                    <li>Осуществлять поиск информации на Сайте.</li>
                    <li>Получать информацию на Сайте.</li>
                    <li>Использовать информацию Сайта в личных некоммерческих целях.</li>
                    <li>Оставлять на Сайте свою контактную информацию и размещать заявки на обучение через предусмотренные для этого формы.</li>
                </ol>
                <p><strong>3. Администрация имеет право</strong></p>
                <ol>
                    <li>Ограничивать доступ к любой информации на Сайте.</li>
                    <li>Отказывать в использовании сервисов Сайта и обработке заявок без объяснения причин.</li>
                </ol>
                <p><strong>4. Обязательства Пользователя</strong></p>
                <ol>
                    <li>Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере&nbsp;интеллектуальной собственности,&nbsp;авторских&nbsp;и/или&nbsp;смежных правах, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.</li>
                    <li>Запрещается использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами.</li>
                    <li>Использование материалов Сайта без согласия&nbsp;правообладателей&nbsp;не допускается (статья 1270&nbsp;Г.К РФ). Для правомерного использования материалов Сайта необходимо заключение&nbsp;лицензионных договоров&nbsp;(получение лицензий) от Правообладателей.</li>
                    <li>При&nbsp;цитировании&nbsp;материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна (подпункт 1 пункта 1 статьи 1274&nbsp;Г.К РФ).</li>
                    <li>Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и использование им внешних ресурсов, ссылки на которые могут содержаться на сайте.</li>
                    <li>Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта,&nbsp;регистрацией авторских прав&nbsp;и сведениями о такой регистрации, товарами или услугами, доступными на Сайте.</li>
                </ol>
                <p><strong>5. Отказ от ответственности</strong></p>
                <ol>
                    <li class="text-uppercase">Администрация Сайта не несет ответственности за посещение Пользователем и использование им внешних ресурсов, ссылки на которые могут содержаться на сайте.</li>
                    <li class="text-uppercase">Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта,&nbsp;регистрацией авторских прав&nbsp;и сведениями о такой регистрации, товарами или услугами, доступными на Сайте.</li>
                    <li class="text-uppercase">Администрация Сайта не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг.</li>
                    <li class="text-uppercase">Администрация Сайта не несет никакой ответственности за услуги, предоставляемые третьими лицами.</li>
                    <li class="text-uppercase">Администрация Сайта не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса.</li>
                </ol>
                <p><strong>6. Условия действия Соглашения</strong></p>
                <ol>
                    <li>Данное Соглашение вступает в силу при любом использовании Сайта.</li>
                    <li>Соглашение перестает действовать при появлении его новой версии.</li>
                    <li>Администрация Сайта оставляет за собой право в одностороннем порядке изменять Соглашение по своему усмотрению.</li>
                    <li>Администрация Сайта не оповещает Пользователей об изменении в Соглашении.</li>
                </ol>
                <p><strong>7. Прочие условия</strong></p>
                <ol>
                    <li>К отношениям Сторон по предоставлению сервисов Сайта на безвозмездной основе не подлежат применению положения законодательства о защите прав потребителей.</li>
                    <li>Обработка персональных данных Пользователя осуществляется в соответствии с законодательством Российской Федерации и регулируется <a href="/politika-konfidencialnosti-anglijskoj-onlajn-shkoly">Политикой конфиденциальности</a>. Поскольку Администрация Сайта осуществляет обработку персональных данных Пользователя в целях исполнения настоящего Соглашения, в силу положений законодательства о персональных данных согласие Пользователя на обработку его персональных данных не требуется.</li>
                    <li>Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.</li>
                    <li>Ничто в Соглашении не может пониматься как установление между Пользователем и Администрации Сайта агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.</li>
                    <li>Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.</li>
                    <li>Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и&nbsp;защиту авторских прав&nbsp;на охраняемые в соответствии с законодательством материалы Сайта.</li>
                </ol>
                <p><strong>Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.</strong><strong> При несогласии Пользователя с настоящим Соглашением он обязан отказаться от доступа к Сайту и немедленно прекратить использование материалов и сервисов Сайта.</strong></p>
                <vc-organization-info title="8. Реквизиты администрации Сайта"></vc-organization-info>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import OrganizationInfo from '../components/OrganizationInfo';

    export default {
        data () {
            return {
            }
        },

        components: {
            'vc-organization-info': OrganizationInfo,
        },
    };
</script>

<style scoped>
    ul, ol {
        margin-bottom: 1rem;
    }
    li {
        margin-bottom: 0.5rem;
    }
</style>
