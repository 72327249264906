<template>
    <v-container class="promo-bg py-0">
        <v-row
                align="center"
                justify="center"
                class="hidden-sm-and-up"
        >
            <v-col
                    class="text-center"
            >
                <p class="mt-3 headline-text-ssm">
                    Занимайтесь&nbsp;онлайн<br>с&nbsp;преподавателем
                </p>
                <p class="mt-3 headline-text">
                    БЕСПЛАТНО
                </p>
                <v-btn class="mt-2 mb-2" color="indigo darken-3" dark x-large @click.stop="dialog = true">
                    Оформить заявку
                </v-btn>
            </v-col>
        </v-row>
        <v-row
                align="center"
                justify="center"
                id="promo-container"
                class="hidden-xs-only"
        >
            <v-col
                    class="px-0 py-0"
                    cols="6"
            >
                <div class="d-flex justify-center fill-width">
                    <v-img
                            :src="require('../assets/promo7.jpg')"
                    ></v-img>
                </div>
            </v-col>
            <v-col
                    class="hidden-sm-and-down"
                    cols="6"
            >
                <v-card
                        flat
                        color="rgba(255, 255, 255, 0)"
                >
                    <v-card-text class="headline-text pb-2">
                        Занимайтесь&nbsp;онлайн<br>с&nbsp;преподавателем
                    </v-card-text>
                    <v-card-text class="super-headline-text">
                        БЕСПЛАТНО
                    </v-card-text>
                    <v-card-text class="text-center">
                        <slot>
                            <v-btn class="mt-4 mb-2" color="indigo darken-3" dark x-large @click.stop="dialog = true">
                                Оформить заявку
                            </v-btn>
                        </slot>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                    class="hidden-xs-only hidden-md-and-up"
                    cols="6"
            >
                <v-card
                        flat
                        color="rgba(255, 255, 255, 0)"
                >
                    <v-card-text class="headline-text-ssm pb-0">
                        Занимайтесь&nbsp;онлайн<br>с&nbsp;преподавателем
                    </v-card-text>
                    <v-card-text class="headline-text-sm">
                        БЕСПЛАТНО
                    </v-card-text>
                    <v-card-text class="text-center">
                        <slot>
                            <v-btn class="mt-2" color="indigo darken-3" dark large @click.stop="dialog = true">
                                Оформить заявку
                            </v-btn>
                        </slot>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                        dark
                        dense
                        color="indigo darken-2"
                >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                                icon
                                dark
                                @click="dialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <vc-request-form
                            type="Заявка на промо"
                            header-icon="mdi-square-edit-outline"
                            header-text="Заявка на бесплатный курс из 32 занятий"
                            :request-age="true"
                            :request-email="true"
                            :verifyPhone="false">
                    </vc-request-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
<!--
    <v-container class="promo-bg py-0" fluid>
        <v-row
                align="center"
                justify="center"
                id="promo-container"
        >
            <v-col
                    class="pt-10 pb-0 hidden-sm-and-down"
            >
                <div class="d-flex justify-center fill-width">
                    <img
                            :src="require('../assets/promo.jpg')"
                            width="300px"
                            height="420px"
                    >
                </div>
            </v-col>
            <v-col
            >
                <v-card
                        flat
                        color="rgba(255, 255, 255, 0)"
                >
                    <v-card-text class="red-text white&#45;&#45;text pb-0">
                        Уникальное предложение!
                    </v-card-text>
                    <v-card-text class="super-headline-text hidden-sm-and-down">
                        32 занятия<br>БЕСПЛАТНО
                    </v-card-text>
                    <v-card-text class="headline-text hidden-md-and-up">
                        32 занятия<br>БЕСПЛАТНО
                    </v-card-text>
                    <v-card-text class="text-center">
                        <slot>
                            <v-btn class="mt-2 mb-2" color="deep-orange" dark x-large v-scroll-to="'#order'">
                                Оформить заявку
                            </v-btn>
                        </slot>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
-->
</template>

<script>
    import RequestForm from '../components/RequestForm';

    export default {
        name: "PromoBanner",

        components: {
            'vc-request-form': RequestForm,
        },

        data() {
            return {
                dialog: false,
            };
        },
    }
</script>

<style scoped>
    .promo-bg {
        background-color: #f1eeee;
    }
    .fill-width {
        width: 100%;
    }
    #promo-container {
        /*min-height: 460px;*/
    }
    #promo-container-sm {
        /*min-height: 300px;*/
    }
</style>