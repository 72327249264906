<template>
    <v-container fluid class="pt-0">
        <v-row
        >
            <v-col class="descr-text text-center">
                При оплате за:
                <a @click="setPriceLevel('1')" :class="linkClass('1')">1 мес</a>&nbsp;/&nbsp;
                <a @click="setPriceLevel('3')" :class="linkClass('3')">3 мес</a>&nbsp;/&nbsp;
                <a @click="setPriceLevel('6')" :class="linkClass('6')">6 мес</a>&nbsp;/&nbsp;
                <a @click="setPriceLevel('12')" :class="linkClass('12')">12 мес</a>
            </v-col>
        </v-row>
        <v-row
                align="stretch"
                justify="space-around"
                class="hidden-sm-and-down"
        >
            <v-col
                    cols="12"
                    md="3"
            >
                <v-card
                        flat
                        tile
                        color="pink lighten-3"
                >
                    <v-card-text class="h3-white-text text-center">
                        Онлайн-самоучитель
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                    cols="12"
                    md="9"
            >
                <v-card
                        flat
                        tile
                        color="teal lighten-2"
                >
                    <v-card-text class="h3-white-text text-center">
                        Занятия с преподавателем
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
                align="stretch"
                justify="space-around"
        >
            <v-col
                    cols="12"
                    md="3"
            >
                <v-hover v-slot:default="{ hover }">
                    <v-card
                            height="100%"
                            raised
                            :elevation="hover ? 15 : 5"
                    >
                        <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                            Виртуальный преподаватель
                        </v-card-text>
                        <v-card-text class="descr-text">
                            Для тех родителей, кто готов лично участвовать в учебном процессе
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text :class="priceTextClass">
                            <p class="mb-0 mt-5"><span class="price-text">{{ prices[priceLevel].virtual[0] }}&nbsp;</span><span class="h2-text">руб</span></p>
                            <p class="h2-text">в&nbsp;месяц</p>
                        </v-card-text>
                        <v-card-text class="regular-text px-0 fill-height" v-if="showButtons">
                            <div class="stick-to-bottom text-center">
                                <v-btn width="200px" color="indigo darken-2" dark @click="openPanel(0)" v-scroll-to="'#panels'">Узнать больше</v-btn>
                                <br>
                                <v-btn width="200px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-besplatno">Тест-драйв</v-btn>
                                <br>
                                <v-btn text class="mb-2" v-scroll-to="'#tariffs-comparision'">Сравнить</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col
                    cols="12"
                    md="3"
            >
                <v-hover v-slot:default="{ hover }">
                    <v-card
                            height="100%"
                            raised
                            :elevation="hover ? 15 : 5"
                    >
                        <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                            Учись бюджетно!
                        </v-card-text>
                        <v-card-text class="descr-text">
                            Для тех, кто хочет получить базовый уровень сервиса за наименьшие деньги
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text :class="priceTextClass">
                            <p class="mb-0 mt-5"><span class="price-text">{{ prices[priceLevel].budget[0] }}&nbsp;</span><span class="h2-text">руб</span></p>
                            <p class="h2-text">в&nbsp;месяц</p>
                        </v-card-text>
                        <v-card-text class="regular-text px-0 fill-height" v-if="showButtons">
                            <div class="stick-to-bottom text-center">
                                <v-btn width="200px" color="indigo darken-2" dark @click="openPanel(1)" v-scroll-to="'#panels'">Узнать больше</v-btn>
                                <br>
                                <v-btn width="200px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-besplatno">Тест-драйв</v-btn>
                                <!--<v-btn width="250px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii">Участвовать в акции</v-btn>-->
                                <br>
                                <v-btn text class="mb-2" v-scroll-to="'#tariffs-comparision'">Сравнить</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col
                    cols="12"
                    md="3"
            >
                <v-hover v-slot:default="{ hover }">
                    <v-card
                            height="100%"
                            raised
                            :elevation="hover ? 15 : 5"
                    >
                        <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                            Золотая середина
                        </v-card-text>
                        <v-card-text class="descr-text">
                            Для тех, кто ищет оптимальное соотношение между ценой и качеством
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text :class="priceTextClass">
                            <p class="mb-0 mt-5"><span class="price-text">{{ prices[priceLevel].gold[0] }}&nbsp;</span><span class="h2-text">руб</span></p>
                            <p class="h2-text">в&nbsp;месяц</p>
                        </v-card-text>
                        <v-card-text class="regular-text px-0 fill-height" v-if="showButtons">
                            <div class="stick-to-bottom text-center">
                                <v-btn width="200px" color="indigo darken-2" dark @click="openPanel(2)" v-scroll-to="'#panels'">Узнать больше</v-btn>
                                <br>
                                <v-btn width="200px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-besplatno">Тест-драйв</v-btn>
                                <!--<v-btn width="250px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii">Участвовать в акции</v-btn>-->
                                <br>
                                <v-btn text class="mb-2" v-scroll-to="'#tariffs-comparision'">Сравнить</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col
                    cols="12"
                    md="3"
            >
                <v-hover v-slot:default="{ hover }">
                    <v-card
                            height="100%"
                            raised
                            :elevation="hover ? 15 : 5"
                    >
                        <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                            V.I.P. - интенсив
                        </v-card-text>
                        <v-card-text class="descr-text">
                            Для тех, у кого в приоритете максимальные скорость и качество
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text :class="priceTextClass">
                            <p class="mb-0 mt-5"><span class="price-text">{{ prices[priceLevel].vip[0] }}&nbsp;</span><span class="h2-text">руб</span></p>
                            <p class="h2-text">в&nbsp;месяц</p>
                        </v-card-text>
                        <v-card-text class="regular-text px-0 fill-height" v-if="showButtons">
                            <div class="stick-to-bottom text-center">
                                <v-btn width="200px" color="indigo darken-2" dark @click="openPanel(3)" v-scroll-to="'#panels'">Узнать больше</v-btn>
                                <br>
                                <v-btn width="200px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-besplatno">Тест-драйв</v-btn>
                                <!--<v-btn width="250px" color="error" class="mt-5 mb-3" dark href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii">Участвовать в акции</v-btn>-->
                                <br>
                                <v-btn text class="mb-2" v-scroll-to="'#tariffs-comparision'">Сравнить</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "PricingBanner",

        props: ['showButtons'],

        data() {
            return {
                priceLevel: '12',
                prices: {
                    '12': {
                            'virtual': ['210', '7'],
                            'budget': ['990', '35'],
                            'gold': ['1890', '60'],
                            'vip': ['4490', '120'],
                    },
                    '6': {
                            'virtual': ['250', '8'],
                            'budget': ['1290', '45'],
                            'gold': ['2190', '70'],
                            'vip': ['5190', '130'],
                    },
                    '3': {
                            'virtual': ['270', '9'],
                            'budget': ['1390', '50'],
                            'gold': ['2390', '80'],
                            'vip': ['5590', '150'],
                    },
                    '1': {
                            'virtual': ['290', '10'],
                            'budget': ['1490', '55'],
                            'gold': ['2490', '85'],
                            'vip': ['5890', '160'],
                    },
        },
            };
        },

        computed: {
            priceTextClass() {
              return this.showButtons ? 'price-text text-center' : 'text-center';
            },
        },

        methods: {
            openPanel(num) {
                this.$root.globalConfig.panelOpened = [num];
            },

            setPriceLevel(level) {
                this.priceLevel = level;
            },

            linkClass(level) {
                return level === this.priceLevel ? 'selectedLink' : '';
            },
        },
    }
</script>

<style scoped>
    .v-sheet {
        border-radius: 8px;
    }

    .selectedLink {
        font-weight: bold;
        font-size: 1.1rem !important;
    }
    .price-text {
        font-size: 2.8rem !important;
        font-weight: 800 !important;
        color: #595959 !important;
    }
</style>