<template>
    <v-container class="py-0" fluid>
        <v-row
                align="center"
                justify="center"
                id="promo-container"
        >
            <v-col
                    class="pt-10 pb-0 hidden-sm-and-down"
            >
                <div class="d-flex justify-center fill-width">
                    <img
                            :src="require('../assets/price.jpg')"
                            width="506px"
                            height="420px"
                    >
                </div>
            </v-col>
            <v-col
            >
                <v-card
                        flat
                        color="rgba(255, 255, 255, 0)"
                >
                    <v-card-text class="h1-text pb-0">
                        Это дешевле, чем вы думаете
                    </v-card-text>
                    <v-card-text class="h00-text">
                        за 990 руб/мес
                    </v-card-text>
                    <v-card-text class="text-center">
                        <slot>
                            <v-btn class="mt-2 mb-2" color="deep-orange" dark x-large v-scroll-to="'#order'">
                                Оформить заявку
                            </v-btn>
                        </slot>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "CheapBanner",
    }
</script>

<style scoped>
    .promo-bg {
        background-color: #f3b433;
    }
    .fill-width {
        width: 100%;
    }
    #promo-container {
        min-height: 460px;
    }
</style>