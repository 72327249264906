<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <vc-promo-banner class="mt-0"></vc-promo-banner>
        <v-container>
            <v-row
                    class="mb-0 mt-1"
                    id="promo"
            >
                <v-col
                >
                    <div class="regular-text">
                        <p>Как известно, лучше один раз увидеть, чем сто раз услышать. Поэтому многие школы и репетиторы предлагают потенциальным клиентам бесплатный пробный урок. Но какие выводы можно сделать на основании одного занятия?</p>
                        <p>Понимая это, мы решили дать возможность всем желающим провести полноценный тест-драйв нашей системы обучения английскому языку.
                        Испытайте ее в деле  и посмотрите сами, насколько она подходит вашему ребенку. Это <span class="important-text">совершенно бесплатно</span> и не накладывает на вас никаких обязательств. Мы не ждем от вас каких-либо гарантий, не требуем депозитов и т.д. По завершении пробного периода вы сами будете решать, продолжать ли вам обучение в нашей школе или нет.</p>
                        <p>Итак, оставьте заявку прямо сейчас и получите в подарок стартовый пакет "Погружение в английский", адресованный детям <span class="important-text">от 6 до 10 лет</span> и включающий в себя  <span class="important-text">целых 32 занятия</span>, в том числе, <span class="important-text">с преподавателем</span> по следующей формуле:</p>
                        <v-container class="px-3">
                            <v-row
                                    align="stretch"
                                    justify="space-around"
                            >
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-card
                                            height="100%"
                                            raised
                                    >
                                        <v-card-text class="h0-text pb-3 centered-text">
                                            8
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text class="title-text centered-text">
                                            тематических видеотренингов
                                            с <span class="nobr">учителем <v-tooltip
                                                top
                                                eager
                                                max-width="400px"
                                        >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small v-bind="attrs" v-on="on" class="sup">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>Тематический видеотренинг - групповой онлайн-урок, проводимый в игровой форме. Как правило, посвящен освоению новой темы либо контролю усвоения и закреплению ранее пройденного материала. Ученикам предлагается общение с преподавателем по видеосвязи, демонстрация визуальных видеоматериалов, участие в конкурсах на знание языка и групповых играх.</span>
                                            </v-tooltip>
                                            </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="1"
                                        align-self="center"
                                        class="text-center"
                                >
                                    <v-icon x-large color="grey darken-1">mdi-plus-circle</v-icon>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-card
                                            height="100%"
                                            raised
                                    >
                                        <v-card-text class="h0-text pb-3 centered-text">
                                            8
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text class="title-text centered-text">
                                            игровых тренингов на <span class="nobr">компьютере <v-tooltip
                                                top
                                                max-width="400px"
                                                eager
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on" class="sup">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Игровой тренинг - индивидуальное упражнение, выполняемое учеником на компьютере и предназначенное для расширения вокабуляра, а также отработки навыков распознавания речи на слух. В процессе обучения используются элементы компьютерных игр, как простейших (различные виды квизов), так и довольно сложных (квесты и прочие аркадные игры).</span>
                                        </v-tooltip>
                                        </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="1"
                                        align-self="center"
                                        class="text-center"
                                >
                                    <v-icon x-large color="grey darken-1">mdi-plus-circle</v-icon>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-card
                                            height="100%"
                                            raised
                                    >
                                        <v-card-text class="h0-text pb-3 centered-text">
                                            16
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text class="title-text centered-text">
                                            листенинг-тренингов на компьютере
                                            или <span class="nobr">смартфоне <v-tooltip
                                                top
                                                eager
                                                max-width="400px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on" class="sup">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Листенинг-тренинг - индивидуальное упражнение на прослушивание, выполняемое учеником на компьютере или смартфоне и предназначенное для тренировки навыков понимания беглой речи, начитанной носителями английского языка. По ходу упражнения система постоянно контролирует внимание ученика и оценивает степень понимания аудиоматериала при помощи контрольных вопросов. Учебные материалы упакованы в мультимедийный формат и, как правило, представляют собой мультфильмы.</span>
                                        </v-tooltip>
                                        </span>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="mt-12">
                                <v-col>
                                    <v-alert
                                            text
                                            outlined
                                            color="deep-orange"
                                            icon="mdi-alert"
                                            class="h2-text"
                                            prominent
                                    >
                                        Предложение ограничено и действует до исчерпания мест в промо-группе. Надо успеть!
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
            <v-row
                    align="stretch"
                    justify="center"
                    class="mt-2"
                    id="order"
            >
                <v-col
                        cols="12"
                        md="10"
                >
                    <vc-request-form
                            type="Заявка на промо"
                            header-icon="mdi-square-edit-outline"
                            header-text="Заявка на бесплатный курс из 32 занятий"
                            :request-age="true"
                            :request-email="true"
                            :verifyPhone="false">
                    </vc-request-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import RequestForm from '../components/RequestForm';
    import PromoBanner from '../components/PromoBanner';

    export default {
        data () {
            return {
            }
        },

        components: {
            'vc-request-form': RequestForm,
            'vc-promo-banner': PromoBanner,
        },

        mounted() {
        },
    };
</script>

<style scoped>
    .v-sheet {
        border-radius: 8px;
    }
    ul, ol {
        margin-bottom: 1.5rem;
    }
    li {
        margin-bottom: 0.5rem;
    }
    .important-text {
        color: orangered !important;
        font-weight: 800 !important;
    }
    .orange-text {
        color: orangered !important;
    }
    .orange-border {
        border-color: orangered !important;
    }
    .centered-text {
        text-align: center !important;
    }
    .sup {
        vertical-align: super;
    }
    .nobr {
        white-space: nowrap;
    }
</style>