var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"descr-text text-center"},[_vm._v("\n            При оплате за:\n            "),_c('a',{class:_vm.linkClass('1'),on:{"click":function($event){return _vm.setPriceLevel('1')}}},[_vm._v("1 мес")]),_vm._v(" / \n            "),_c('a',{class:_vm.linkClass('3'),on:{"click":function($event){return _vm.setPriceLevel('3')}}},[_vm._v("3 мес")]),_vm._v(" / \n            "),_c('a',{class:_vm.linkClass('6'),on:{"click":function($event){return _vm.setPriceLevel('6')}}},[_vm._v("6 мес")]),_vm._v(" / \n            "),_c('a',{class:_vm.linkClass('12'),on:{"click":function($event){return _vm.setPriceLevel('12')}}},[_vm._v("12 мес")])])],1),_c('v-row',{staticClass:"hidden-sm-and-down",attrs:{"align":"stretch","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"flat":"","tile":"","color":"pink lighten-3"}},[_c('v-card-text',{staticClass:"h3-white-text text-center"},[_vm._v("\n                    Онлайн-самоучитель\n                ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-card',{attrs:{"flat":"","tile":"","color":"teal lighten-2"}},[_c('v-card-text',{staticClass:"h3-white-text text-center"},[_vm._v("\n                    Занятия с преподавателем\n                ")])],1)],1)],1),_c('v-row',{attrs:{"align":"stretch","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","raised":"","elevation":hover ? 15 : 5}},[_c('v-card-text',{staticClass:"h2-white-text font-weight-medium pb-3 card-title text-center"},[_vm._v("\n                        Виртуальный преподаватель\n                    ")]),_c('v-card-text',{staticClass:"descr-text"},[_vm._v("\n                        Для тех родителей, кто готов лично участвовать в учебном процессе\n                    ")]),_c('v-divider'),_c('v-card-text',{class:_vm.priceTextClass},[_c('p',{staticClass:"mb-0 mt-5"},[_c('span',{staticClass:"price-text"},[_vm._v(_vm._s(_vm.prices[_vm.priceLevel].virtual[0])+" ")]),_c('span',{staticClass:"h2-text"},[_vm._v("руб")])]),_c('p',{staticClass:"h2-text"},[_vm._v("в месяц")])]),(_vm.showButtons)?_c('v-card-text',{staticClass:"regular-text px-0 fill-height"},[_c('div',{staticClass:"stick-to-bottom text-center"},[_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#panels'),expression:"'#panels'"}],attrs:{"width":"200px","color":"indigo darken-2","dark":""},on:{"click":function($event){return _vm.openPanel(0)}}},[_vm._v("Узнать больше")]),_c('br'),_c('v-btn',{staticClass:"mt-5 mb-3",attrs:{"width":"200px","color":"error","dark":"","href":"/anglijskij-yazyk-dlya-detej-besplatno"}},[_vm._v("Тест-драйв")]),_c('br'),_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#tariffs-comparision'),expression:"'#tariffs-comparision'"}],staticClass:"mb-2",attrs:{"text":""}},[_vm._v("Сравнить")])],1)]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","raised":"","elevation":hover ? 15 : 5}},[_c('v-card-text',{staticClass:"h2-white-text font-weight-medium pb-3 card-title text-center"},[_vm._v("\n                        Учись бюджетно!\n                    ")]),_c('v-card-text',{staticClass:"descr-text"},[_vm._v("\n                        Для тех, кто хочет получить базовый уровень сервиса за наименьшие деньги\n                    ")]),_c('v-divider'),_c('v-card-text',{class:_vm.priceTextClass},[_c('p',{staticClass:"mb-0 mt-5"},[_c('span',{staticClass:"price-text"},[_vm._v(_vm._s(_vm.prices[_vm.priceLevel].budget[0])+" ")]),_c('span',{staticClass:"h2-text"},[_vm._v("руб")])]),_c('p',{staticClass:"h2-text"},[_vm._v("в месяц")])]),(_vm.showButtons)?_c('v-card-text',{staticClass:"regular-text px-0 fill-height"},[_c('div',{staticClass:"stick-to-bottom text-center"},[_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#panels'),expression:"'#panels'"}],attrs:{"width":"200px","color":"indigo darken-2","dark":""},on:{"click":function($event){return _vm.openPanel(1)}}},[_vm._v("Узнать больше")]),_c('br'),_c('v-btn',{staticClass:"mt-5 mb-3",attrs:{"width":"200px","color":"error","dark":"","href":"/anglijskij-yazyk-dlya-detej-besplatno"}},[_vm._v("Тест-драйв")]),_c('br'),_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#tariffs-comparision'),expression:"'#tariffs-comparision'"}],staticClass:"mb-2",attrs:{"text":""}},[_vm._v("Сравнить")])],1)]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","raised":"","elevation":hover ? 15 : 5}},[_c('v-card-text',{staticClass:"h2-white-text font-weight-medium pb-3 card-title text-center"},[_vm._v("\n                        Золотая середина\n                    ")]),_c('v-card-text',{staticClass:"descr-text"},[_vm._v("\n                        Для тех, кто ищет оптимальное соотношение между ценой и качеством\n                    ")]),_c('v-divider'),_c('v-card-text',{class:_vm.priceTextClass},[_c('p',{staticClass:"mb-0 mt-5"},[_c('span',{staticClass:"price-text"},[_vm._v(_vm._s(_vm.prices[_vm.priceLevel].gold[0])+" ")]),_c('span',{staticClass:"h2-text"},[_vm._v("руб")])]),_c('p',{staticClass:"h2-text"},[_vm._v("в месяц")])]),(_vm.showButtons)?_c('v-card-text',{staticClass:"regular-text px-0 fill-height"},[_c('div',{staticClass:"stick-to-bottom text-center"},[_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#panels'),expression:"'#panels'"}],attrs:{"width":"200px","color":"indigo darken-2","dark":""},on:{"click":function($event){return _vm.openPanel(2)}}},[_vm._v("Узнать больше")]),_c('br'),_c('v-btn',{staticClass:"mt-5 mb-3",attrs:{"width":"200px","color":"error","dark":"","href":"/anglijskij-yazyk-dlya-detej-besplatno"}},[_vm._v("Тест-драйв")]),_c('br'),_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#tariffs-comparision'),expression:"'#tariffs-comparision'"}],staticClass:"mb-2",attrs:{"text":""}},[_vm._v("Сравнить")])],1)]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"height":"100%","raised":"","elevation":hover ? 15 : 5}},[_c('v-card-text',{staticClass:"h2-white-text font-weight-medium pb-3 card-title text-center"},[_vm._v("\n                        V.I.P. - интенсив\n                    ")]),_c('v-card-text',{staticClass:"descr-text"},[_vm._v("\n                        Для тех, у кого в приоритете максимальные скорость и качество\n                    ")]),_c('v-divider'),_c('v-card-text',{class:_vm.priceTextClass},[_c('p',{staticClass:"mb-0 mt-5"},[_c('span',{staticClass:"price-text"},[_vm._v(_vm._s(_vm.prices[_vm.priceLevel].vip[0])+" ")]),_c('span',{staticClass:"h2-text"},[_vm._v("руб")])]),_c('p',{staticClass:"h2-text"},[_vm._v("в месяц")])]),(_vm.showButtons)?_c('v-card-text',{staticClass:"regular-text px-0 fill-height"},[_c('div',{staticClass:"stick-to-bottom text-center"},[_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#panels'),expression:"'#panels'"}],attrs:{"width":"200px","color":"indigo darken-2","dark":""},on:{"click":function($event){return _vm.openPanel(3)}}},[_vm._v("Узнать больше")]),_c('br'),_c('v-btn',{staticClass:"mt-5 mb-3",attrs:{"width":"200px","color":"error","dark":"","href":"/anglijskij-yazyk-dlya-detej-besplatno"}},[_vm._v("Тест-драйв")]),_c('br'),_c('v-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#tariffs-comparision'),expression:"'#tariffs-comparision'"}],staticClass:"mb-2",attrs:{"text":""}},[_vm._v("Сравнить")])],1)]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }