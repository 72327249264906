<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-container fluid class="pb-0">
            <v-row

            >
                <v-col
                        class="text-center"
                >
                    <p class="mt-3 h0-text">Английский - в каждый дом!</p>
                    <p class="regular-text mt-3 mb-0 pb-0">Предлагаем на выбор четыре простых тарифных плана</p>
                </v-col>
            </v-row>
        </v-container>
        <vc-pricing-banner :showButtons="true"></vc-pricing-banner>
        <v-container>
<!--
            <v-row
                    class="mt-3 d-none d-md-flex"
            >
                <v-col
                        class="text-right"
                        align-self="center"
                >
                    <span class="h2-text">Цена</span>
                </v-col>
                <v-col
                        align-self="center"
                >
                    <v-img :src="require('../assets/arrow.png')"></v-img>
                </v-col>
                <v-col
                        align-self="center"
                >
                    <span class="h2-text">Качество</span>
                </v-col>
            </v-row>
-->

            <!--
                    <v-row
                            align="start"
                            justify="center"
                    >
                        <v-col>
                            <v-alert
                                    text
                                    outlined
                                    color="deep-orange"
                                    icon="mdi-fire"
                                    prominent
                                    transition="scale-transition"
                                    class="mx-12 mt-7"
                            >
                                <v-row>
                                    <v-col class="grow ml-5">
                                        <p class="headline font-weight-bold">Получите две недели бесплатного доступа к нашей системе!</p>
                                        <p class="title mt-2"></p>
                                        <v-btn class="mt-3 d-lg-none" color="deep-orange" dark x-large :to="{ name: 'promo' }">Узнать больше</v-btn>
                                    </v-col>
                                    <v-col class="shrink d-none d-lg-flex" align-self="center">
                                        <v-btn class="mr-10" color="deep-orange" dark x-large :to="{ name: 'promo' }">Узнать больше</v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row
                            class="mb-0"
                    >
                        <v-col
                                class="text-center"
                        >
                            <p class="mt-12 h1-text">Требуется обучать нескольких детей?</p>
                            <p class="h2-text mt-3 mb-5">Специально для вас - тариф "Семейный"</p>
                        </v-col>
                    </v-row>
                    <v-row
                            align="stretch"
                            justify="center"
                    >
                        <v-col
                                cols="12"
                                md="3"
                                align-self="center"
                                class="text-center"
                        >
                            <v-avatar color="indigo darken-2" size="64" class="mb-5">
                                <v-icon x-large dark>mdi-account-multiple</v-icon>
                            </v-avatar>
                            <p class="regular-text text-center">за&nbsp;двоих&nbsp;детей</p>
                            <p class="mb-0 text-center"><span class="h0-text">2&nbsp;500&nbsp;</span><span class="h1-text">руб</span></p>
                            <p class="h2-text text-center">в&nbsp;месяц</p>
                            <p class="mt-0 text-center"><v-chip color="deep-orange" class="h1-white-text font-weight-bold" large dark>-25%</v-chip></p>
                        </v-col>
                        <v-col
                                cols="12"
                                md="1"
                                align-self="center"
                                class="text-center"
                        >
                        </v-col>
                        <v-col
                                cols="12"
                                md="3"
                                align-self="center"
                                class="text-center"
                        >
                            <v-avatar color="indigo darken-2" size="64" class="mb-5">
                                <v-icon x-large dark>mdi-account-group</v-icon>
                            </v-avatar>
                            <p class="regular-text text-center">за&nbsp;троих&nbsp;детей</p>
                            <p class="mb-0 text-center"><span class="h0-text">3&nbsp;200&nbsp;</span><span class="h1-text">руб</span></p>
                            <p class="h2-text text-center">в&nbsp;месяц</p>
                            <p class="mt-0 text-center"><v-chip color="deep-orange" class="h1-white-text font-weight-bold" dark large>-35%</v-chip></p>
                        </v-col>
                    </v-row>
                    <v-row
                            class="mb-0 mt-3"
                    >
                        <v-col
                                class="text-center"
                        >
                            <v-btn text x-large @click="openPanel(3)" v-scroll-to="'#panels'">[ Подробнее о тарифном плане ]</v-btn>
                        </v-col>
                    </v-row>
                    -->

            <v-row
                    align="center"
                    justify="center"
            >
                <v-col class="text-center">
                    <p class="mt-10 h1-text">Подробнее о тарифных планах</p>
                </v-col>
            </v-row>
            <v-row
                    align="center"
                    justify="center"
            >
                <v-col>
                    <v-expansion-panels id="panels" popout multiple v-model="panelOpened">

                        <v-expansion-panel
                                id="panel-0"
                        >
                            <v-expansion-panel-header class="title-text">
                                Виртуальный преподаватель
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager class="regular-text">
                                <p>Этот тарифный план адресован родителям, которые предпочитают держать учебный процесс под личным контролем и готовы активно в нем участвовать. Вам самим придется контролировать усвоение ребенком нового и ранее пройденного материала, а также обеспечивать учебную дисциплину.</p>
                                <p>На самом деле, в этом нет ничего сложного, вам не понадобятся даже базовые познания английского - вы можете учиться вместе с ребенком. Школа обеспечит вас всесторонней методической поддержкой. Для объяснения ребенку нового материала вы сможете использовать видеоролики, созданные нашими преподавателями. Интеллектуальная система управления обучением будет самостоятельно направлять ход учебного процесса.</p>
                                <p>Более того, основную часть тренингов ребенок будет проходить без вашего участия наедине с компьютером, который сам будет формировать для него задания и выставлять оценки. Вам останется лишь контролировать успеваемость и, при необходимости, восполнять выявленные пробелы в знаниях.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                                id="panel-1"
                        >
                            <v-expansion-panel-header class="title-text">
                                Учись бюджетно!
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager class="regular-text">
                                <p>Этот тарифный план подойдет тем родителям, кто не готов плотно участвовать в учебном процессе и хотел бы доверить объяснение материала преподавателям Школы, но при этом желает сэкономить по максимуму.
                                    Мы оптимизировали тариф так, чтобы предоставить базовый уровень сервиса за наименьшие деньги.</p>
                                <p>Тематические видеотренинги с преподавателями учащиеся проходят в составе групп размером до 12 человек. Все домашние задания выполняются на компьютере, формирующем индивидуальный набор упражнений для каждого ученика. Контроль за исполнением учебной дисциплины возлагается на самих родителей.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                                id="panel-1"
                        >
                            <v-expansion-panel-header class="title-text">
                                Золотая середина
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager class="regular-text">
                                <p>Этот тарифный план оптимизирован так, чтобы обеспечить наилучшее соотношение цены и качества.</p>
                                <p>Размер групп уменьшен до 8 человек, а количество тематических видеотренингов увеличено до 3 в неделю. Уроки ведут преподаватели с большим опытом работы.</p>
                                <p>Все связанные с обучением хлопоты Школа берет на себя. Контролёры Школы осуществляют оперативный надзор за соблюдением учебной дисциплины, своевременно сообщая родителям о выявленных проблемах. Персонал школы внимательно следит за успеваемостью, при необходимости внося в учебный процесс необходимые коррективы.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                                id="panel-2"
                        >
                            <v-expansion-panel-header class="title-text">
                                V.I.P.-интенсив
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager class="regular-text">
                                <p>Этот тарифный план предназначен для наиболее взыскательных клиентов, предъявляющих самые высокие требования к качеству оказываемых услуг и желающих, чтобы их ребенок освоил английский в максимально короткие сроки.</p>
                                <p>Занятия проводятся лучшими преподавателями Школы в мини-группах до 4 человек. Хронометраж занятий увеличен. Упражнения на развитие спонтанной речи проходят с носителями языка, при этом количество таких упражнений увеличено по сравнению с другими тарифами.
                                    Это позволяет уделить максимум времени каждому ученику и гарантирует его прохождение по траектории обучения в оптимальном режиме.</p>
                                <p>Данный тариф может быть также интересен родителям детей с особыми потребностями, требующих повышенного внимания со стороны преподавателя.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!--                    <v-expansion-panel
                                                    id="panel-3"
                                            >
                                                <v-expansion-panel-header class="title-text">
                                                    Семейный
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content eager class="regular-text">
                                                    <p>Этот тарифный план&nbsp;позволит неплохо сэкономить тем, кому необходимо обучать одновременно двух и более детей. При этом дети необязательно должны быть родственниками. Вы можете скооперироваться с кем угодно.</p>
                                                    <p>Важно лишь, чтобы соблюдался&nbsp;ряд условий:</p>
                                                    <ul>
                                                        <li>Со Школой взаимодействует только один взрослый на всех учеников (в т.ч. вносит плату за обучение).</li>
                                                        <li>Ученики занимаются по одной и той же программе в одной и той же группе.</li>
                                                        <li>Приостановка обучения, перевод в другую группу и другие существенные изменения в условиях обучения возможны лишь для всех учеников, входящих в семью, одновременно.</li>
                                                        <li>Ученики проходят видеотренинги с преподавателем совместно, сидя рядом друг с другом за одним компьютером. Все самостоятельные тренинги каждый ученик проходит отдельно (система формирует для каждого индивидуальный набор заданий).</li>
                                                    </ul>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>-->
                    </v-expansion-panels>
                </v-col>
            </v-row>

            <v-row
                    align="center"
                    justify="center"
            >
                <v-col class="text-center">
                    <p class="mt-14 h1-text">Сравнение тарифных планов</p>
                </v-col>
            </v-row>
            <v-row
                    align="center"
                    justify="center"
                    class="mt-0"
            >
                <v-col class="text-center">
                    <v-card class="no-radius">
                        <v-simple-table id="tariffs-comparision">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left"></th>
                                    <th class="text-center">Виртуальный преподаватель</th>
                                    <th class="text-center">Учись бюджетно!</th>
                                    <th class="text-center">Золотая середина</th>
                                    <th class="text-center">V.I.P.-интенсив</th>
                                </tr>
                                </thead>
                                <tbody>
<!--
                                <tr>
                                    <td class="text-left font-weight-medium">Сколько вы платите?</td>
                                    <td class="text-center">290 руб&nbsp;/&nbsp;мес</td>
                                    <td class="text-center">1&nbsp;490 руб&nbsp;/&nbsp;мес</td>
                                    <td class="text-center">2&nbsp;490 руб&nbsp;/&nbsp;мес</td>
                                    <td class="text-center">5&nbsp;890 руб&nbsp;/&nbsp;мес</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Сколько стоит учебное время?</td>
                                    <td class="text-center">10 руб&nbsp;/&nbsp;ак.&nbsp;час</td>
                                    <td class="text-center">50 руб&nbsp;/&nbsp;ак.&nbsp;час</td>
                                    <td class="text-center">90 руб&nbsp;/&nbsp;ак.&nbsp;час</td>
                                    <td class="text-center">150 руб&nbsp;/&nbsp;ак.&nbsp;час</td>
                                </tr>
-->
                                <tr>
                                    <td class="text-left font-weight-medium">Кто объясняет новый материал?</td>
                                    <td class="text-center">Обучающие видеоролики</td>
                                    <td class="text-center">Преподаватель</td>
                                    <td class="text-center">Старший преподаватель</td>
                                    <td class="text-center">Магистр Школы</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Кто контролирует успеваемость?</td>
                                    <td class="text-center">Родитель</td>
                                    <td class="text-center">Школа</td>
                                    <td class="text-center">Школа</td>
                                    <td class="text-center">Школа</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Кто контролирует дисциплину?</td>
                                    <td class="text-center">Родитель</td>
                                    <td class="text-center">Родитель</td>
                                    <td class="text-center">Школа</td>
                                    <td class="text-center">Школа</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Как проводятся видеоуроки?</td>
                                    <td class="text-center">По самоучителю</td>
                                    <td class="text-center">В составе группы</td>
                                    <td class="text-center">В составе группы</td>
                                    <td class="text-center">В составе мини-группы</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Сколько учеников в группе?</td>
                                    <td class="text-center">Нет</td>
                                    <td class="text-center">до 12</td>
                                    <td class="text-center">до 8</td>
                                    <td class="text-center">до 4</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Как составляется учебный план?</td>
                                    <td class="text-center">Индивидуально</td>
                                    <td class="text-center">Для группы</td>
                                    <td class="text-center">Для группы</td>
                                    <td class="text-center">Для мини-группы</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Как формируются учебные задания?</td>
                                    <td class="text-center">Автоматически</td>
                                    <td class="text-center">Автоматически</td>
                                    <td class="text-center">Автоматически</td>
                                    <td class="text-center">Автоматически</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Допускается ли перенос занятий?</td>
                                    <td class="text-center">Жесткого графика нет</td>
                                    <td class="text-center">Не допускается</td>
                                    <td class="text-center">Не допускается</td>
                                    <td class="text-center">По договоренности</td>
                                </tr>
                                <!--
                                                            <tr>
                                                                <td class="text-left font-weight-medium">Политика возвратов и переносов</td>
                                                                <td class="text-center">Жесткая</td>
                                                                <td class="text-center">Жесткая</td>
                                                                <td class="text-center">Гибкая</td>
                                                            </tr>
                                -->
                                <!--
                                                            <tr>
                                                                <td class="text-left font-weight-medium">Доступ к языковым турнирам</td>
                                                                <td class="text-center">Бесплатно</td>
                                                                <td class="text-center">Бесплатно</td>
                                                                <td class="text-center">Бесплатно</td>
                                                            </tr>
                                -->
                                <tr>
                                    <td class="text-left font-weight-medium">Speaking-тренингов в месяц</td>
                                    <td class="text-center">Нет</td>
                                    <td class="text-center">до 4</td>
                                    <td class="text-center">до 4</td>
                                    <td class="text-center">до 8</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Кто проводит speaking-тренинги?</td>
                                    <td class="text-center">Не включены в тариф</td>
                                    <td class="text-center">Преподаватель</td>
                                    <td class="text-center">Старший преподаватель</td>
                                    <td class="text-center">Носитель</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Уровень техподдержки</td>
                                    <td class="text-center">Ограниченный</td>
                                    <td class="text-center">Ограниченный</td>
                                    <td class="text-center">Полный</td>
                                    <td class="text-center">Полный</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Занятий с преподавателем в месяц</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">8&nbsp;-&nbsp;9</td>
                                    <td class="text-center">12&nbsp;-&nbsp;14</td>
                                    <td class="text-center">12&nbsp;-&nbsp;14</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Длительность 1 занятия с преподавателем</td>
                                    <td class="text-center">Нет</td>
                                    <td class="text-center">25&nbsp;-&nbsp;35&nbsp;мин</td>
                                    <td class="text-center">25&nbsp;-&nbsp;35&nbsp;мин</td>
                                    <td class="text-center">35&nbsp;-&nbsp;50&nbsp;мин</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Компьютерных тренингов в месяц</td>
                                    <td class="text-center">44&nbsp;-&nbsp;48</td>
                                    <td class="text-center">44&nbsp;-&nbsp;48</td>
                                    <td class="text-center">44&nbsp;-&nbsp;48</td>
                                    <td class="text-center">44&nbsp;-&nbsp;48</td>
                                </tr>
                                <tr>
                                    <td class="text-left font-weight-medium">Общий объем занятий в месяц</td>
                                    <td class="text-center">ок. 30 акад. часов</td>
                                    <td class="text-center">ок. 28 акад. часов</td>
                                    <td class="text-center">ок. 30 акад. часов</td>
                                    <td class="text-center">ок. 38 акад. часов</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row
                    class="mb-0"
            >
                <v-col
                        class="text-center"
                >
                    <p class="mt-14 h1-text">Необходимы дополнительные уроки с преподавателем?</p>
                    <p class="regular-text mt-3">Пригодится тем, кому по каким-то причинам недостаточно типовой программы</p>
                </v-col>
            </v-row>
            <v-row
                    align="stretch"
                    justify="space-around"
            >
                <v-col
                        cols="12"
                        md="3"
                >
                    <v-hover v-slot:default="{ hover }">
                        <v-card
                                height="100%"
                                raised
                                :elevation="hover ? 15 : 5"
                        >
                            <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                                Эконом
                            </v-card-text>
                            <v-card-text class="descr-text">
                                Сокращенная программа обучения, длительность каждого занятия 27&nbsp;мин
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="extra-price-text text-center">
                                <p class="mb-0 mt-0"><span class="h0-text">250</span><span class="h1-text">&nbsp;руб</span></p>
                                <p class="mt-0 h2-text">за&nbsp;занятие</p>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col
                        cols="12"
                        md="3"
                >
                    <v-hover v-slot:default="{ hover }">
                        <v-card
                                height="100%"
                                raised
                                :elevation="hover ? 15 : 5"
                        >
                            <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                                Стандарт
                            </v-card-text>
                            <v-card-text class="descr-text">
                                Полная программа обучения, длительность каждого занятия составляет 40&nbsp;мин
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="extra-price-text text-center">
                                <p class="mb-0 mt-0"><span class="h0-text">350</span><span class="h1-text">&nbsp;руб</span></p>
                                <p class="mt-0 h2-text">за&nbsp;занятие</p>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col
                        cols="12"
                        md="3"
                >
                    <v-hover v-slot:default="{ hover }">
                        <v-card
                                height="100%"
                                raised
                                :elevation="hover ? 15 : 5"
                        >
                            <v-card-text class="h2-white-text font-weight-medium pb-3 card-title text-center">
                                Speaking
                            </v-card-text>
                            <v-card-text class="descr-text">
                                Специальное упражнение для развития навыков спонтанной речи
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="text-center extra-price-text">
                                <p class="mb-0 mt-0"><span class="h1-text">от</span>&nbsp;&nbsp;<span class="h0-text">150</span><span class="h1-text">&nbsp;руб</span></p>
                                <p class="mt-0 h2-text">за&nbsp;занятие</p>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>

            <v-row
                    align="stretch"
                    justify="space-around"
                    class="mt-14 mb-12"
            >
                <v-col
                        cols="12"
                        md="4"
                >
                    <v-card
                            height="100%"
                            raised
                    >
                        <v-card-title class="h2-text font-weight-medium pb-3">
                            Остались вопросы?
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="regular-text">
                            Посетите <a href="/otvety-na-voprosy-po-obucheniyu-anglijskomu-yazyku">раздел часто задаваемых вопросов</a> или свяжитесь с нами
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                        cols="12"
                        md="4"
                >
                    <v-card
                            height="100%"
                            raised
                    >
                        <v-card-title class="h2-text font-weight-medium pb-3">
                            Хотите дешевле?
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="regular-text">
                            Спросите нас, и мы подскажем, как уменьшить стоимость обучения
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import PricingBanner from '../components/PricingBanner';

    export default {
        data() {
            return {
            }
        },

        computed: {
            panelOpened() {
                return this.$root.globalConfig.panelOpened;
            },
        },

        components: {
            'vc-pricing-banner': PricingBanner,
        },
    };
</script>

<style scoped>
    .v-sheet {
        border-radius: 8px;
    }
    .no-radius {
        border-radius: 0px !important;
    }
    th {
        font-size: 1.3rem !important;
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
        color: #424242 !important;
    }
    td {
        font-size: 1.2rem !important;
        line-height: 1.8rem !important;
        color: #424242 !important;
        padding-top: 1.3rem !important;
        padding-bottom: 1.3rem !important;
    }
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .03);
    }
    a {
        text-decoration: none;
    }
    .mt-14 {
        margin-top: 120px;
    }
</style>
