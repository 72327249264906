import Vue from 'vue';
import VueYouTubeEmbed from 'vue-youtube-embed';
import VueScrollTo from 'vue-scrollto';
// import VueGoogleCharts from 'vue-google-charts';
// import VueJivosite from '@bchteam/vue-jivosite'
import Tawk from 'vue-tawk';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Fragment from 'vue-fragment';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;
Vue.use(VueYouTubeEmbed);
Vue.use(VueScrollTo);
// Vue.use(VueGoogleCharts);

// Vue.use(VueJivosite, { widgetId: '0MsupxYSan' });

/*Vue.use(Tawk, {
    tawkSrc: 'https://embed.tawk.to/5d99a2da6c1dde20ed053cab/default'
});*/

Vue.use(Fragment.Plugin);

Vue.use(VueYandexMetrika, {
    id: 69393085,
    router: router,
    env: process.env.NODE_ENV,
    // other options
    debug: true,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        trackHash:true,
    },
});

Vue.use(VueGtag,
    {
        config: { id: 'AW-473854621' },
        includes: [ { id: 'G-RKEX93DB78' } ],
    },
    router,
);

const myPlugin = {
    install() {
        Vue.prototype.$gtagReportConversion = function() {
            this.$gtag.query('event', 'conversion', {
                'send_to': 'AW-473854621/y8JUCLmvtuoBEJ3l-eEB',
            });
        };
        Vue.prototype.$registerUrl = function() {
            if (typeof(Storage) === 'undefined') return;
            let userTrack = [];
            if (localStorage.userTrack18798273987) {
                userTrack = JSON.parse(localStorage.userTrack18798273987);
            }
            const record = {
                date: new Date(),
                url: window.location.href.replace(window.location.origin, ''),
            };
            userTrack.push(record);
            localStorage.userTrack18798273987 = JSON.stringify(userTrack);
            // console.log(localStorage.userTrack18798273987);
        };
        Vue.prototype.$getUserTrack = function() {
            if (typeof(Storage) === 'undefined') return [];
            if (localStorage.userTrack18798273987) return JSON.parse(localStorage.userTrack18798273987);
            return [];
        };
    }
};

Vue.use(myPlugin);

const root = new Vue({
    el: '#app',
    router,
    vuetify,
    render: h => h(App),
    data: {
        globalConfig: {
            panelOpened: [],
        },
    },
    mounted () {
        // You'll need this for renderAfterDocumentEvent.
        document.dispatchEvent(new Event('render-event'));
    },
});

document.addEventListener('DOMContentLoaded', function () {
    root.$mount('#app')
});
