<template>
    <v-container>
        <v-row
                align="center"
                justify="center"
        >
            <v-col class="text-center">
                <p class="mt-3 h0-text">Мы ждем ваших вопросов</p>
            </v-col>
        </v-row>
        <v-row
                align="stretch"
                justify="space-around"
                class="mt-3 mb-12"
        >
            <v-col
                    cols="12"
                    md="4"
            >
                <v-card
                        height="100%"
                        raised
                >
                    <v-card-title class="h2-text font-weight-medium pb-3">
                        <v-icon large class="mr-3">mdi-email</v-icon>Электронная почта
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="regular-text">
                        <span class="title-text"><a href="mailto:info@loadstarschool.com">info@loadstarschool.com</a></span>
                        <br>в любое время
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                    cols="12"
                    md="4"
            >
                <v-card
                        height="100%"
                        raised
                >
                    <v-card-title class="h2-text font-weight-medium pb-3">
                        <v-icon large class="mr-3">mdi-phone-classic</v-icon>Телефон
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="regular-text">
                        <span class="title-text"><a href="tel:+74994041185">+7&nbsp;(499)&nbsp;404-11-85</a></span>
                        <br>по будням, с 9 до 18 по Москве
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
                align="stretch"
                justify="center"
        >
            <v-col
                    cols="12"
                    md="10"
            >
                <vc-request-form
                        type="Заказ обратного звонка"
                        header-icon="mdi-phone-in-talk"
                        header-text="Закажите обратный звонок"
                        actionText="Позвоните мне"
                        :request-age="false"
                        :request-email="false"
                        :verifyPhone="false">
                </vc-request-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import RequestForm from '../components/RequestForm';

    export default {
        data () {
            return {
            }
        },

        components: {
            'vc-request-form': RequestForm,
        },

        mounted() {
        },
    };
</script>

<style scoped>
    a {
        text-decoration: none;
    }
</style>