<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card
            id="request-form"
            height="100%"
            raised
            v-intersect="onIntersect"
    >
        <v-card-title class="h2-text bg-darken font-weight-medium pb-3">
            <v-icon large class="mr-2">{{ headerIcon }}</v-icon>
            {{ headerText }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="regular-text">
            <div id="form-cont"
            >
                <v-form
                        ref="form"
                        v-model="userDataValid"
                        v-if="status === 'FormFilling'"
                >
                    <div v-if="verifyPhone">
                        <p class="regular-text">Просим Вас обратить внимание, что в заявке необходимо указать <span class="important-text">работающий телефон,</span> на который Вы сможете принять наш звонок.
                            При отправке заявки будет произведена верификация телефона.</p>
                        <p class="regular-text">Если у Вас нет номера, на который Вы можете принимать звонки, отправьте заявку в произвольной форме на WhatsApp или Viber <a href="https://wa.me/79254919410">+7&nbsp;(925)&nbsp;491-94-10</a></p>
                    </div>
                    <v-text-field
                            v-model="name"
                            :rules="[
                    v => !!v || 'Поле обязательно для заполнения!',
                ]"
                            label="Ваше имя *"
                            required
                            name="name"
                            autocomplete="name"
                            @focus="onFocus"
                    ></v-text-field>

                    <v-text-field
                            v-model="age"
                            v-if="requestAge"
                            label="Возраст ребенка, который будет заниматься *"
                            type="number"
                            :rules="ageRules"
                            required
                            autocomplete="off"
                            @focus="onFocus"
                    ></v-text-field>

                    <v-text-field
                            v-model="phone"
                            :rules="phoneRules"
                            label="Контактный телефон в международном формате *"
                            required
                            type="tel"
                            name="phone"
                            autocomplete="tel"
                            @focus="onFocus"
                    ></v-text-field>

                    <v-text-field
                            v-if="requestEmail"
                            v-model="email"
                            label="E-mail"
                            type="email"
                            name="email"
                            autocomplete="email"
                            @focus="onFocus"
                    ></v-text-field>

                    <v-text-field
                            v-model="info"
                            label="Доп. информация (например, удобные вам время и способ связи)"
                            @focus="onFocus"
                    ></v-text-field>

                    <v-checkbox
                            v-model="agree"
                            :rules="[v => !!v || 'Вы должны принять Пользовательское соглашение!']"
                            required
                    >
                        <template v-slot:label>
                            <div>
                                Перед заполнением формы я ознакомился с условиями <a v-on:click.stop.prevent="goToAgreement">Пользовательского соглашения</a> и принимаю их в полном объеме
                            </div>
                        </template>
                    </v-checkbox>
                    <v-alert
                            v-model="snackbarFail"
                            dense
                            type="error"
                    >
                        К сожалению, произошла ошибка! Попробуйте отправить запрос снова или напишите нам в мессенджер либо по e-mail.
                    </v-alert>

                    <v-btn
                            :disabled="!userDataValid"
                            :loading="sending"
                            color="indigo darken-2"
                            x-large
                            class="mt-4 white--text"
                            @click="submit"
                    >
                        {{ buttonText }}
                    </v-btn>
                </v-form>

                <div
                        v-else-if="status === 'VerificationWarning'"
                >
                    <p class="regular-text">Для завершения оформления заявки, необходимо подтвердить телефонный номер.
                        Для этого после нажатия кнопки ДАЛЕЕ мы выполним <span class="important-text">автоматический контрольный звонок</span> на указанный Вами номер {{ phone }}.
                        Звонок можно сбросить, отвечать на него не нужно. Просто запомните <span class="important-text">последние 4 цифры нашего номера,</span> с которого Вам поступит звонок.
                        Их необходимо будет ввести на следующем шаге. </p>
                    <p class="regular-text">Если у Вас нет номера, на который Вы можете принимать звонки, отправьте заявку в произвольной форме на WhatsApp или Viber <a href="https://wa.me/79254919410">+7&nbsp;(925)&nbsp;491-94-10</a></p>

                    <v-alert
                            v-model="snackbarFail"
                            dense
                            type="error"
                    >
                        К сожалению, произошла ошибка! Попробуйте отправить запрос снова или напишите нам в мессенджер либо по e-mail.
                    </v-alert>

                    <v-btn
                            :disabled="sending"
                            color="error"
                            x-large
                            class="mt-4 mr-5 white--text"
                            @click="backToFormFilling"
                    >
                        Назад
                    </v-btn>

                    <v-btn
                            :loading="sending"
                            color="indigo darken-2"
                            x-large
                            class="mt-4 white--text"
                            @click="startVerification"
                    >
                        Далее
                    </v-btn>
                </div>

                <v-form
                        ref="form"
                        v-model="phoneValid"
                        v-else-if="status === 'PhoneVerification'"
                >
                    <p class="regular-text">Введите <span class="important-text">последние 4 цифры нашего номера,</span> с которого Вам поступил звонок.
                        Если звонок не поступил в течение нескольких минут, попробуйте указать другой номер либо отправьте заявку иным способом. </p>
                    <v-text-field
                            v-model="code"
                            label="Последние 4 цифры номера, с которого поступил звонок *"
                            type="number"
                            :rules="codeRules"
                            required
                            autocomplete="off"
                    ></v-text-field>

                    <v-alert
                            v-model="snackbarFail"
                            dense
                            type="error"
                    >
                        К сожалению, произошла ошибка! Попробуйте отправить запрос снова или напишите нам в мессенджер либо по e-mail.
                    </v-alert>

                    <v-btn
                            :disabled="sending"
                            color="error"
                            x-large
                            class="mt-4 mr-5 white--text"
                            @click="backToFormFilling"
                    >
                        Назад
                    </v-btn>

                    <v-btn
                            :disabled="!phoneValid"
                            :loading="sending"
                            color="indigo darken-2"
                            x-large
                            class="mt-4 white--text"
                            @click="submitVerificationCode"
                    >
                        Подтвердить
                    </v-btn>
                </v-form>

                <div
                        v-else-if="status === 'Success'"
                >
                    <p class="h1-text mx-10 text-center">Спасибо, что выбрали нас!</p>
                    <p class="h2-text mx-10 text-center">Ваш запрос успешно отправлен. Наши сотрудники в ближайшее время свяжутся с Вами.</p>
                    <p class="h3-text mx-10 mt-5 text-center">Пожалуйста, заранее внесите наши номера <span class="important-text">+7&nbsp;(499)&nbsp;404&#8209;11&#8209;85</span> и <span class="important-text">+7&nbsp;(925)&nbsp;491&#8209;94&#8209;10</span> в список своих контактов</p>
                </div>
            </div>
        </v-card-text>
    </v-card>

</template>

<script>
    import axios from 'axios';
    import libphonenumber from 'google-libphonenumber';
    import emailValidator from 'email-validator';

    export default {
        name: 'RequestForm',

        data() {
            return {
                userDataValid: false,
                phoneValid: false,
                id: 0,
                name: '',
                age: '',
                ageRules: [
                    v => !!v || 'Поле обязательно для заполнения!',
                    v => v && parseInt(v) > 4 && parseInt(v) < 12 || 'Заявки рассматриваются для детей в возрасте от 5 до 11 лет!',
                ],
                codeRules: [
                    v => !!v || 'Поле обязательно для заполнения!',
                    v => v === '3400' || 'Неверный код подтверждения',
                ],
                phone: '',
                phoneRules: [
                    v => !!v || 'Поле обязательно для заполнения!',
                    v => (!!v && this.phoneIsValid(v)) || 'Введите номер телефона в международном формате, например: +7 (901) 123 45 67',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'Поле обязательно для заполнения!',
                    v => emailValidator.validate(v) || 'Введите email в корректном формате, например: mailbox@example.com',
                ],
                info: '',
                code: '',
                agree: false,
                status: 'FormFilling',
                sending: false,
                snackbarFail: false,
                http: null,
                controllerAddress: 'https://loadstar.school:8443',
                // controllerAddress: 'https://localhost:8081',
                isIntersecting: false,
                enteringNotStarted: true,
            };
        },

        props: ['type', 'headerIcon', 'headerText', 'actionText', 'requestEmail', 'requestAge', 'verifyPhone'],

        computed: {
          buttonText() {
            return this.actionText ? this.actionText : 'Далее';
          },
        },

        methods: {
            goToAgreement() {
                this.$router.push('/polzovatelskoe-soglashenie-kursov-anglijskogo-dlya-detej');
            },

            scrollToForm() {
                const el = document.getElementById('request-form');
                el.scrollIntoView();
            },

            phoneIsValid(num) {
                const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                try {
                    const parsedNum = phoneUtil.parseAndKeepRawInput(num);
                    return phoneUtil.isValidNumber(parsedNum);
                }
                catch (e) {
                    return false;
                }
            },

            submit() {
                this.snackbarFail = false;
                this.sending = true;
                const data = {
                    id: this.id,
                    type: this.type,
                    name: this.name,
                    phone: this.phone,
                    age: this.age,
                    email: this.email,
                    info: this.info,
                    track: this.$getUserTrack(),
                };
                this.http.post('notifications/feedback', data)
                    .then(() => {
                        this.sending = false;
                        if (this.verifyPhone) {
                            this.status = 'VerificationWarning';
                        } else {
                            try {
                                this.$metrika.reachGoal('form ' + this.type);
                            }
                            catch (e) {
                                console.error(e);
                            }
                            try {
                                this.$gtagReportConversion();
                            }
                            catch (e) {
                                console.error(e);
                            }
                            this.status = 'Success';
                        }
                        this.scrollToForm();
                    })
                    .catch(() => {
                        this.sending = false;
                        this.snackbarFail = true;
                    });
            },

            startVerification() {
                this.snackbarFail = false;
                this.sending = true;
                const data = {
                    name: this.name,
                    phone: this.phone,
                };
                this.http.post('notifications/verify', data)
                    .then(() => {
                        this.sending = false;
                        this.status = 'PhoneVerification';
                        this.scrollToForm();
                    })
                    .catch(() => {
                        this.sending = false;
                        this.snackbarFail = true;
                    });
            },

            submitVerificationCode() {
                this.snackbarFail = false;
                this.sending = true;
                const data = {
                    id: this.id,
                    name: this.name,
                    phone: this.phone,
                };
                this.http.post('notifications/verified', data)
                    .then(() => {
                        try {
                            this.$metrika.reachGoal('form ' + this.type);
                        }
                        catch (e) {
                            console.error(e);
                        }
                        try {
                            this.$gtagReportConversion();
                        }
                        catch (e) {
                            console.error(e);
                        }
                        this.sending = false;
                        this.status = 'Success';
                        this.scrollToForm();
                    })
                    .catch(() => {
                        this.sending = false;
                        this.snackbarFail = true;
                    });
            },

            backToFormFilling() {
                this.snackbarFail = false;
                this.code = '';
                this.status = 'FormFilling';
                this.scrollToForm();
            },

            onIntersect (entries, observer) {
                this.isIntersecting = entries[0].isIntersecting;
                if (this.type === 'Заявка на промо' && this.isIntersecting){
                    this.$metrika.reachGoal('form visible');
                }
            },

            onFocus() {
                if (this.type === 'Заявка на промо' && this.enteringNotStarted){
                    this.enteringNotStarted = false;
                    this.$metrika.reachGoal('start entering');
                }
            }
        },

        mounted() {
            this.http = axios.create({
                baseURL: `${this.controllerAddress}/api`,
                withCredentials: false,
            });
            this.id = (new Date()).getTime();
        },
    };
</script>

<style scoped>
    .snack-text {
        font-size: 0.8rem !important;
        line-height: 1.2rem !important;
        color: #FFFFFF !important;
        word-break: normal !important;
    }
    .important-text {
        color: orangered !important;
        font-weight: 800 !important;
    }
    #form-cont {
        min-height: 200px;
    }
</style>