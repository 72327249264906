import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Program from './views/Program.vue'
import Pricing from './views/Pricing.vue'
import Promo from './views/Promo.vue'
import Registration from './views/Registration.vue'
import Faq from './views/Faq.vue'
import Contact from './views/Contact.vue'
import Terms from './views/Terms.vue'
import Privacy from './views/Privacy.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/programma-obucheniya-detej-anglijskomu-yazyku',
            name: 'program',
            component: Program,
        },
        {
            path: '/ceny-na-onlajn-kursy-anglijskogo-yazyka',
            name: 'pricing',
            component: Pricing,
        },
/*
        {
            path: '/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii',
            name: 'promo',
            component: Promo,
        },
        {
            path: '/anglijskij-yazyk-dlya-detej-besplatno',
            name: 'registration',
            component: Registration,
        },
*/
        {
            path: '/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii',
            name: 'promo2',
            component: Promo,
        },
        {
            path: '/anglijskij-yazyk-dlya-detej-besplatno',
            name: 'promo',
            component: Promo,
        },
        {
            path: '/otvety-na-voprosy-po-obucheniyu-anglijskomu-yazyku',
            name: 'faq',
            component: Faq,
        },
        {
            path: '/kontakty-anglijskoj-onlajn-shkoly',
            name: 'contact',
            component: Contact,
        },
        {
            path: '/polzovatelskoe-soglashenie-kursov-anglijskogo-dlya-detej',
            name: 'terms',
            component: Terms,
        },
        {
            path: '/politika-konfidencialnosti-anglijskoj-onlajn-shkoly',
            name: 'privacy',
            component: Privacy,
        },
    ]
})
