<template>
    <v-app>
        <v-main class="overflow-hidden">
            <v-toolbar flat dark short color="indigo darken-2" class="hidden-sm-and-down">
                <v-toolbar-title>
                    <a href="/">
                        <v-img
                                :src="require('./assets/logo-big-white.png')"
                                width="200px"
                                contain
                        >
                        </v-img>
                    </a>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!--<v-btn small color="red darken-4" class="ml-10" dark href="https://intellischool.ru">Личный кабинет</v-btn>-->
                <!--<v-btn color="red darken-4" class="ml-10 yellow&#45;&#45;text" dark href="/anglijskij-yazyk-dlya-detej-besplatno">Попробовать бесплатно</v-btn>-->
                <v-icon class="mr-1">mdi-phone-classic</v-icon><a class="white--text no-decor-text" href="tel:+74994041185">+7&nbsp;(499)&nbsp;404-11-85</a>
            </v-toolbar>
            <v-toolbar flat dense class="hidden-sm-and-down"> <!--color="#F3F3F3">-->
                <v-toolbar-title class="text-left">
                    <span class="main-title-text">
                        <a href="/">
                            Языковая онлайн-школа для детей от 5 до 12 лет
                        </a>
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-btn text href="/" exact>Главная</v-btn>
                    <v-btn text href="/programma-obucheniya-detej-anglijskomu-yazyku" exact>Программа</v-btn>
                    <v-btn text href="/ceny-na-onlajn-kursy-anglijskogo-yazyka" exact>Цены</v-btn>
                    <v-btn text href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii" exact color="red darken-4">Акции</v-btn>
                    <v-btn text href="/otvety-na-voprosy-po-obucheniyu-anglijskomu-yazyku" exact>FAQ</v-btn>
                    <v-btn text href="/kontakty-anglijskoj-onlajn-shkoly" exact>Контакты</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-toolbar
                    flat
                    color="indigo darken-2"
                    class="hidden-md-and-up">
                <v-toolbar-title>
                    <a href="/">
                        <v-img
                                :src="require('./assets/logo-big-white.png')"
                                width="200px"
                                contain
                        >
                        </v-img>
                    </a>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon
                        class="hidden-md-and-up"
                        color="white"
                        @click.stop="drawer = !drawer"
                >
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-navigation-drawer
                    v-model="drawer"
                    right
                    absolute
                    temporary
            >
                <v-list
                        nav
                        dense
                >
                    <v-list-item-group
                    >
                        <v-list-item exact :to="{ name: 'home' }">
                            <v-list-item-title>Главная</v-list-item-title>
                        </v-list-item>

                        <v-list-item exact :to="{ name: 'program' }">
                            <v-list-item-title>Программа</v-list-item-title>
                        </v-list-item>

                        <v-list-item exact :to="{ name: 'pricing' }">
                            <v-list-item-title>Цены</v-list-item-title>
                        </v-list-item>

                        <v-list-item exact :to="{ name: 'faq' }">
                            <v-list-item-title>FAQ</v-list-item-title>
                        </v-list-item>

                        <v-list-item :to="{ name: 'contact' }">
                            <v-list-item-title>Контакты</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <div class="main-viewport hidden-xs-only">
                <router-view></router-view>
            </div>
            <div class="hidden-sm-and-up pb-15">
                <router-view></router-view>
            </div>
            <div class="stick-to-bottom hidden-xs-only">
<!--
                <v-toolbar dense>
                    <v-toolbar-items class="mx-auto">
                        <v-btn text href="/programma-obucheniya-detej-anglijskomu-yazyku" exact>Программа</v-btn>
                        <v-btn text href="/ceny-na-onlajn-kursy-anglijskogo-yazyka" exact>Цены</v-btn>
                        <v-btn text href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii" exact color="red darken-4">Акции</v-btn>
                        <v-btn text href="/otvety-na-voprosy-po-obucheniyu-anglijskomu-yazyku" exact>FAQ</v-btn>
                        <v-btn text href="/kontakty-anglijskoj-onlajn-shkoly" exact>Контакты</v-btn>
                    </v-toolbar-items>


                </v-toolbar>
-->
                <v-footer
                        color="indigo darken-2" dark
                >
                    <v-container>
                        <v-row
                        >
                            <v-col class="text-left">
                                <p class="mb-3 text-uppercase font-weight-bold">Карта сайта</p>
                                <p class="mb-1"><a class="white--text no-decor-text" href="/">Главная страница</a></p>
                                <p class="mb-1"><a class="white--text no-decor-text" href="/programma-obucheniya-detej-anglijskomu-yazyku">Программа обучения</a></p>
                                <p class="mb-1"><a class="white--text no-decor-text" href="/ceny-na-onlajn-kursy-anglijskogo-yazyka">Цены и условия</a></p>
                                <!--<p class="mb-1"><a class="white&#45;&#45;text no-decor-text" href="/anglijskij-yazyk-dlya-detej-nedorogo-po-akcii">Спецпредложения</a></p>-->
                                <p class="mb-1"><a class="white--text no-decor-text" href="/otvety-na-voprosy-po-obucheniyu-anglijskomu-yazyku">Вопрос-ответ</a></p>
                                <p><a class="white--text no-decor-text" href="/kontakty-anglijskoj-onlajn-shkoly">Контакты</a></p>
                            </v-col>
                            <v-col class="text-left">
                                <p class="mb-3 text-uppercase font-weight-bold">Документы</p>
                                <p class="mb-1"><a class="white--text no-decor-text" href="/polzovatelskoe-soglashenie-kursov-anglijskogo-dlya-detej">Пользовательское соглашение</a></p>
                                <p><a class="white--text no-decor-text" href="/politika-konfidencialnosti-anglijskoj-onlajn-shkoly">Политика конфиденциальности</a></p>
                            </v-col>
                            <v-col class="text-left">
                                <p class="mb-3 text-uppercase font-weight-bold">Контакты</p>
                                <div class="mb-1"><v-icon class="mr-3">mdi-email</v-icon><a class="white--text no-decor-text" href="mailto:info@loadstarschool.com">info@loadstarschool.com</a></div>
                                <div><v-icon class="mr-3">mdi-phone-classic</v-icon><a class="white--text no-decor-text" href="tel:+74994041185">+7&nbsp;(499)&nbsp;404-11-85</a></div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center">
                                &copy; 2023 Loadstar School - All Rights Reserved
                            </v-col>
                        </v-row>
                    </v-container>
                </v-footer>
            </div>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        drawer: false,
        //
    }),

    mounted() {
        this.$registerUrl();
    },
};
</script>

<style>
    .bg-dark {
        background-color: #F1F1F1 !important;
    }
    .bg-darken {
        background-color: #EEEEEE !important;
    }
    .v-expansion-panel--active .v-expansion-panel-header {
        background-color: #F1F1F1 !important;
    }
    .v-expansion-panel--active .v-expansion-panel-content__wrap {
        margin-top: 15px !important;
    }
    .v-card__title {
        word-break: normal !important;
    }
    .stick-to-bottom {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
    .main-viewport {
        margin-bottom: 360px;
        width: 100%;
    }
    .regular-text {
        font-size: 1.1rem !important;
        line-height: 1.8rem !important;
        color: #595959 !important;
    }
    .small-text {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        color: #595959 !important;
    }
    .title-text {
        font-size: 1.2rem !important;
        line-height: 1.8rem !important;
        font-weight: 500 !important;
        color: #595959 !important;
    }
    .headline-text {
        font-size: 2.1rem !important;
        line-height: 2.4rem !important;
        font-weight: 800 !important;
        color: #1A237E !important;
        text-align: center !important;
    }
    .headline-text-sm {
        font-size: 2rem !important;
        line-height: 2.3rem !important;
        font-weight: 800 !important;
        color: #1A237E !important;
        text-align: center !important;
    }
    .headline-text-ssm {
        font-size: 1.2rem !important;
        line-height: 1.5rem !important;
        font-weight: 600 !important;
        color: #1A237E !important;
        text-align: center !important;
    }
    .super-headline-text {
        font-size: 3.2rem !important;
        line-height: 3.7rem !important;
        font-weight: 800 !important;
        color: #1A237E !important;
        text-align: center !important;
    }
    .superbig-text {
        font-size: 9rem !important;
    }
    .red-text {
        font-size: 1.5rem !important;
        line-height: 1.7rem !important;
        font-weight: 800 !important;
        text-align: center !important;
        text-transform: uppercase !important;
        letter-spacing: 3px !important;
        color: #1A237E !important;
    }
    .red-text-sm {
        font-size: 1rem !important;
        line-height: 1.2rem !important;
        font-weight: 600 !important;
        text-align: center !important;
        text-transform: uppercase !important;
        letter-spacing: 3px !important;
        color: #1A237E !important;
    }
    .h00-text {
        font-size: 2.1rem !important;
        line-height: 2.6rem !important;
        font-weight: 600 !important;
        color: #595959 !important;
    }
    .h0-text {
        font-size: 2.1rem !important;
        line-height: 2.6rem !important;
        font-weight: 600 !important;
        color: #595959 !important;
    }
    .h0-white-text {
        font-size: 2.1rem !important;
        line-height: 2.6rem !important;
        font-weight: 500 !important;
        color: #FFFFFF !important;
    }
    .h11-text {
        font-size: 2.3rem !important;
        line-height: 3rem !important;
        font-weight: 600 !important;
        color: #ff5722 !important;
        word-break: normal !important;
    }
    .h1-text {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        font-weight: 600 !important;
        color: #595959 !important;
        word-break: normal !important;
    }
    .h1-white-text {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        font-weight: 500 !important;
        color: #FFFFFF !important;
        word-break: normal !important;
    }
    .h22-text {
        font-size: 1.4rem !important;
        line-height: 2rem !important;
        font-weight: 600 !important;
        color: #ff5722 !important;
        word-break: normal !important;
    }
    .h2-text {
        font-size: 1.4rem !important;
        line-height: 2rem !important;
        font-weight: 500 !important;
        color: #595959 !important;
        word-break: normal !important;
    }
    .h33-text {
        font-size: 1.3rem !important;
        line-height: 1.8rem !important;
        font-weight: 500 !important;
        color: #595959 !important;
        word-break: normal !important;
    }
    .h3-text {
        font-size: 1.2rem !important;
        line-height: 1.8rem !important;
        font-weight: 500 !important;
        color: #595959 !important;
        word-break: normal !important;
    }
    .h3-white-text {
        font-size: 1.2rem !important;
        line-height: 1.8rem !important;
        font-weight: 500 !important;
        color: #FFF !important;
        word-break: normal !important;
    }
    .li-white-text li {
        font-size: 1.4rem !important;
        line-height: 1.6rem !important;
        font-weight: 500 !important;
        color: #fff !important;
        word-break: normal !important;
        margin-bottom: 1rem;
    }
    .h2-white-text {
        font-size: 1.6rem !important;
        line-height: 2rem !important;
        font-weight: 500 !important;
        color: #FFFFFF !important;
        word-break: normal !important;
    }
    .h0-yellow-text {
        font-size: 2.7rem !important;
        line-height: 3.2rem !important;
        font-weight: 600 !important;
        color: #fff887 !important;
    }
    .main-title-text {
        font-size: 1.2rem !important;
        font-weight: 600 !important;
        color: #303F9F !important;
    }
    .main-title-text a {
        text-decoration: none;
        color: #303F9F !important;
    }
    .no-decor-text {
        text-decoration: none !important;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
    .card-title {
        height: 100px;
        background-color: #303F9F;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .descr-text {
        /*margin-bottom: 100px;*/
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        color: #595959 !important;
        text-align: justify !important;
        text-justify: inter-word !important;
        /*margin-right: 15px !important;*/
        /*margin-left: 15px !important;*/
    }
    .price-text {
        margin-bottom: 110px;
    }
    .extra-price-text {
        margin-bottom: 5px;
    }
    .active-tab {
        font-weight: 800 !important;
        font-size: 0.9em !important;
    }
</style>