<template>
    <div v-show="showOrganizationInfo">
        <p><strong>{{ title }}</strong></p>
        <p>ИП Антипенков Николай Викторович</p>
        <p>Свидетельство о государственной регистрации 50 №014278118, выдано 28.01.2016 налоговым органом: Инспекция Федеральной налоговой службы по г.Наро-Фоминску Московской области</p>
        <p>ОГРНИП 316503000050554</p>
        <p>ИНН 503001501908</p>
        <p>Регистрация: 143362, Московская область, Наро-Фоминский район, г. Апрелевка, ул. Цветочная аллея, д.15, кв.119</p>
        <p>E-mail: info@loadstarschool.com</p>
        <p>Телефон: +7 (499) 404-11-85</p>
    </div>
</template>

<script>
    export default {
        name: 'OrganizationInfo',

        props: ['title'],

        data () {
            return {
                showOrganizationInfo: false,
            }
        },
    };
</script>
