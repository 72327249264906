<template>
    <div>
        <v-timeline-item
                color="indigo darken-2"
                left
                icon="mdi-plus"
        >
            <span v-if="course.comment" slot="opposite" class="comment-text">{{ course.comment }}</span>
            <v-card>
                <v-card-title class="indigo darken-2 py-3 px-5">
                    <h2 class="h2-white-text">{{ course.title }}</h2>
                </v-card-title>
                <v-container>
                    <v-row>
                        <v-col class="regular-text px-5">
                            {{ course.description }}
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-timeline-item>
        <v-timeline-item
                hide-dot
                right
        >
            <span class="h2-text ml-4">Что отрабатываем?</span>
        </v-timeline-item>
        <v-timeline-item
                v-for="(stage, i) in course.stages"
                :key="i"
                color="indigo darken-2"
                right
                small
        >
            <span slot="opposite" class="h3-text">Этап {{ i + 1 }}</span>
            <v-card>
                <v-container>
                    <v-row>
                        <v-col class="small-text px-5 py-0">
                            <p
                                    v-for="(paragraph, j) in stage"
                                    :key="j"
                                    class="my-2"
                            >
                                {{ paragraph }}
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-timeline-item>
        <v-timeline-item
                color="indigo darken-2"
                right
                class="mt-5"
                icon="mdi-check"
        >
            <v-card>
                <v-card-title class="darken-2 py-3 px-5">
                    <h2 class="h2-text">Что умеем по окончании курса?</h2>
                </v-card-title>
                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col class="regular-text px-5 py-0">
                            <p><span class="h3-text">Аудирование: </span><span v-html="dash(course.results.listen)"></span></p>
                            <p><span class="h3-text">Говорение: </span> <span v-html="dash(course.results.speak)"></span></p>
                            <p><span class="h3-text">Чтение: </span> <span v-html="dash(course.results.read)"></span></p>
                            <p><span class="h3-text">Пассивный словарный запас: </span> {{ course.results.dictionary.p }} слов</p>
                            <p><span class="h3-text">Активный словарный запас: </span> {{ course.results.dictionary.a }} слов</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-timeline-item>
    </div>
</template>

<script>
    export default {
        name: 'PlanItem',

        data() {
            return {
            };
        },

        components: {
        },

        props: ['course'],

        methods: {
            dash(text) {
                if (text) return text;
                return '-';
            },

            insertTooltips(text) {
                // const result = '<span>' + this.dash(text).replace(/(L\d)/g, this.createTooltip('$1')) + '</span>';
                // const result = `
                //     <vc-tooltip
                //         text="${text}"
                //     >
                //     </vc-tooltip>
                //     `;
                return '123777';
            },
        },
    };
</script>

<style scoped>
    .comment-text {
        font-size: 1.5rem !important;
        line-height: 2rem !important;
        font-weight: 700 !important;
        color: #303F9F !important;
        word-break: normal !important;
    }
    .v-sheet {
        border-radius: 8px;
    }
</style>